import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';

const owaNetVersion: Map<string, string> = new Map<string, string>();

export function setOwaNetVersion(owaNetVersionHeader: string, mailboxInfo: MailboxInfo) {
    owaNetVersion.set(getIndexerValueForMailboxInfo(mailboxInfo), owaNetVersionHeader);
}

export function getOwaNetVersion(mailboxInfo: MailboxInfo): string | undefined {
    return owaNetVersion.get(getIndexerValueForMailboxInfo(mailboxInfo));
}
