import type MailboxInfo from './MailboxInfo';
import getAccountKeyForMailboxInfo from './getAccountKeyForMailboxInfo';
import isSameCoprincipalAccountByAccountKeys from './isSameCoprincipalAccountByAccountKeys';
import type AccountKey from './AccountKey';

/**
 * This function will convert the mailboxInfo to an account key and compare it with
 * the account key passed in to let us know if they are of the same coprincipal account.
 *
 * A direct comparison of the user email addresses or userIdentity is no longer
 * sufficient as the same user can have multiple accounts.
 *
 * @param mailbox1Info You can pass in undefined for ease of use and it will return false.
 * @param mailbox2AccountKey You can pass in undefined for ease of use and it will return false.
 * @returns true if the two account keys are valid and of the same coprincipal account.
 */
export default function isSameCoprincipalAccountByMailboxAndAccountKey(
    mailbox1Info: MailboxInfo | undefined | null,
    mailbox2AccountKey: AccountKey | undefined | null
): boolean {
    let isSame = false;

    if (mailbox1Info) {
        const mailbox1AccountKey = getAccountKeyForMailboxInfo(mailbox1Info);

        isSame = isSameCoprincipalAccountByAccountKeys(mailbox1AccountKey, mailbox2AccountKey);
    }

    return isSame;
}
