import type { MailboxInfo } from 'owa-client-types';
import { isLicensingMailboxInfo } from 'owa-account-source-list-types/lib/utils/isLicensingMailboxInfo';
import { PolicyStateTypeEnum } from 'owa-account-source-list-types';
import { getConfig } from '../config';

/**
 * Determines if the supplied mailbox info is a licensing mailbox info and if it is in a policy error state
 * @param mailboxInfo MailboxInfo to be checked
 * @returns True if the mailbox is in a policy error state, false otherwise
 */
export function isLicensingMailboxInPolicyErrorState(mailboxInfo?: MailboxInfo): boolean {
    if (!!mailboxInfo && isLicensingMailboxInfo(mailboxInfo)) {
        if (mailboxInfo.policyState == PolicyStateTypeEnum.PolicyError) {
            return true;
        }

        // In case the MailboxInfo is stale check the account source list store
        const config = getConfig();
        return (
            config?.getLicensingMailboxPolicyState?.(mailboxInfo) == PolicyStateTypeEnum.PolicyError
        );
    }

    // Either a Mailbox was not passed in, or it is not a licensing mailbox. In both of these cases
    // we return false because we could not determine a policy error state.
    return false;
}
