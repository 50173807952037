import type { TraceErrorObject } from 'owa-trace';

export default function assert(
    condition: any,
    message: string,
    getDiagnosticInfo?: () => string
): asserts condition {
    if (!condition) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        const error: TraceErrorObject = new Error(`Assertion:${message}`);
        error.additionalInfo = { diagnosticInfo: getDiagnosticInfo?.() };
        throw error;
    }
}
