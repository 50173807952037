import { logStartUsage } from 'owa-analytics-start';
import type { CustomDataMap } from 'owa-analytics-types';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { delay } from 'owa-sleep/lib/delay';
import { isNetworkBlocked, isOfflineBootEnabled } from './offlineBoot';
import { isOffline } from './isOffline';

type NetworkRaceResult<T> = {
    value: T;
    customData: CustomDataMap;
    participants: 'offline' | 'network' | 'both';
    winner: 'offline' | 'network';
};
export type RaceParticipant = any;

/**
 * Helper function to create races between online and offline requests.
 *
 * Instead of passing promises you pass thunks that return promises to avoid
 * executing requests that are not needed.
 *
 * Return networkCall when offline boot is disabled.
 * Return offlineFallback when offline.
 * Races between networkCall and offlineFallback when online.
 *
 * @param label - label for analytics
 * @param networkCallThunk - thunk that returns a promise for the network call
 * @param offlineFallbackThunk - thunk that returns a promise for the offline fallback
 * @param raceCompleteCallback - callback to execute after all promises have resolved
 * @param networkTimeout - timeout for network call in ms before falling back to offline
 * @param shouldUseNetworkOnly - if true, only network call will be made. by default is checks for isOfflineBootEnabled
 * @param shouldUseOfflineOnly - if true, only offline call will be made. by default isOffline || isNetworkBlocked
 */
export function networkRace<T>(
    label: string,
    networkCallThunk: () => Promise<T>,
    offlineFallbackThunk: () => Promise<T>,
    raceCompleteCallback?: (networkValue: T, offlineValue: T, winner: RaceParticipant) => void,
    networkTimeout: number = 0,
    shouldUseNetworkOnly: boolean = !isOfflineBootEnabled(),
    shouldUseOfflineOnly: boolean = isOffline() || isNetworkBlocked()
): Promise<NetworkRaceResult<T>> {
    const customData: CustomDataMap = { label };
    const start = Date.now();
    const endTiming = () => (customData['duration'] = Date.now() - start);
    const endDpWithSingleParticipant = (winner: 'offline' | 'network') => (value: T) => {
        endTiming();
        customData['winner'] = winner;
        logStartUsage('NetworkRace', customData);
        return {
            value,
            customData,
            participants: winner,
            winner,
        };
    };

    if (shouldUseNetworkOnly) {
        customData['participants'] = 'network';
        return networkCallThunk().then(endDpWithSingleParticipant('network'));
    }

    if (shouldUseOfflineOnly) {
        customData['participants'] = 'offline';
        return offlineFallbackThunk().then(endDpWithSingleParticipant('offline'));
    }

    customData['participants'] = 'both';

    const racePromises = [networkCallThunk(), delay(offlineFallbackThunk(), networkTimeout)];

    return any(racePromises, label, customData).then(([winner, value]) => {
        if (raceCompleteCallback) {
            Promise.all(racePromises)
                .then(([networkResult, offlineResult]) =>
                    raceCompleteCallback(networkResult, offlineResult, winner)
                )
                .catch(() => {});
        }
        return {
            value,
            customData,
            participants: 'both',
            winner: winner === 1 ? 'network' : 'offline',
        };
    });
}

/**
 * Similar to Promise.any but with analytics
 */
function any<T>(
    promises: Promise<T>[],
    label: string,
    customData: CustomDataMap
): Promise<[number, T]> {
    const start = Date.now();

    return new Promise((resolve, reject) => {
        const errors: any[] = [];
        const rejectAll = (error: any, p: number) => {
            const scrubbed = scrubForPii(error?.message);
            if (p === 1) {
                customData['networkError'] = scrubbed;
            } else {
                customData['offlineError'] = scrubbed;
            }
            errors.push(error);
            logStartUsage('RaceError', {
                l: label,
                t: Date.now() - start,
                p,
                error: scrubbed,
            });
            if (errors.length === promises.length) {
                logStartUsage('NetworkRace', customData);
                // if all promises fail, reject with network error
                reject(p === 1 ? errors[1] : errors[0]);
            }
        };

        let isResolved = false;
        const resolveOnce = (p: number) => (value: T) => {
            if (!isResolved) {
                isResolved = true;
                customData['winner'] = p;
                customData['duration'] = Date.now() - start;
                logStartUsage('NetworkRace', customData);
                resolve([p, value]);
            } else {
                logStartUsage('RaceParticipant', {
                    l: label,
                    t: Date.now() - start,
                    p,
                });
            }
        };

        let participantCount = 1;
        for (const promise of promises) {
            const participant = participantCount++;
            promise.then(resolveOnce(participant), (error: any) => rejectAll(error, participant));
        }
    });
}
