// If the image object is not retained, there can be situations where
// the image is preloaded in advance but then gets garbage collected.
// As a result, when the image is needed, the preloading effort is wasted,
// and a second request must be made.
// To prevent this, the image object is kept on the promise itself.
const cachePreloadedImages: {
    [imageUrl: string]: Promise<HTMLImageElement | null>;
} = {};

export async function preloadThemeImage(imageUrl: string): Promise<HTMLImageElement | null> {
    const preloadImage = (url: string): Promise<HTMLImageElement | null> =>
        new Promise(resolve => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(img);
            img.onerror = () => resolve(null);
        });

    let preloadPromise = cachePreloadedImages[imageUrl];
    if (!preloadPromise) {
        cachePreloadedImages[imageUrl] = preloadPromise = preloadImage(imageUrl);
    }

    return preloadPromise;
}

export async function cleanupPreloadedThemeImage(imageUrl: string) {
    const preloadPromise = cachePreloadedImages[imageUrl];
    if (preloadPromise) {
        await preloadPromise;
        delete cachePreloadedImages[imageUrl];
    }
}
