import type { StartSelfProfilerFunction } from 'owa-analytics-types';
import safeRequestAnimationFrame from './safeRequestAnimationFrame';
import { getQueryStringParameter } from 'owa-querystring';

const primaryTimingMap: Map<number, string> = new Map();
const secondaryTimingMap: Map<number, string> = new Map();
const annotationMap: Map<number, string> = new Map();

export function getTimingMaps() {
    return { primary: primaryTimingMap, secondary: secondaryTimingMap };
}

export function getAnnotationMap() {
    return annotationMap;
}

export function clearTimingMap() {
    primaryTimingMap.clear();
    annotationMap.clear();
}

type PrimaryTimingPrefix =
    | 'commandExecutor'
    | 'contentEditor'
    | 'eventListener'
    | 'governor'
    | 'keytrap'
    | 'mruCache'
    | 'observable'
    | 'onload'
    | 'onfinish'
    | 'perfObserver'
    | 'reactionScheduler'
    | 'resetFocus'
    | 'setInterval'
    | 'setTimeout'
    | 'useCustomAnimationFrame'
    | 'useCustomTimeout'
    | 'useEffect'
    | 'workerResponse'
    | 'workerCallback'
    | 'useResizeObserver'
    | 'yieldIdle'
    | 'yieldNow';

type SecondaryTimingPrefix = 'satchelAction';

const secondaryActions: {
    [_ in SecondaryTimingPrefix]: boolean;
} = {
    satchelAction: true,
};

type TimingPrefix = PrimaryTimingPrefix | SecondaryTimingPrefix;

let profileFunc: StartSelfProfilerFunction | null = null;
export function setProfileFunc(func: StartSelfProfilerFunction) {
    profileFunc = func;
}

export function addToTimingMap(prefix: TimingPrefix, value: string) {
    if (typeof performance?.now === 'function') {
        const source = `${prefix}:${value}`;
        const timingMap = prefix in secondaryActions ? secondaryTimingMap : primaryTimingMap;
        timingMap.set(performance.now(), source);

        const markTimingValue = getQueryStringParameter('markTiming');
        if (markTimingValue == '*' || (markTimingValue && source.includes(markTimingValue))) {
            performance.mark(`timingMap:${source}`);
        }

        if (profileFunc) {
            const endProfile = profileFunc(source, { minDuration: 1000 });
            if (endProfile) {
                safeRequestAnimationFrame(isVisibile => {
                    setTimeout(() => {
                        endProfile(isVisibile);
                    });
                });
            }
        }
    }
}

type AnnotationPrefix = 'ForcedLayout';

export function addToAnnotationMap(prefix: AnnotationPrefix, value: string, timeParam?: number) {
    const time =
        timeParam ?? (typeof performance?.now === 'function' ? performance.now() : undefined);
    if (time != undefined) {
        annotationMap.set(time, `${prefix}:${value}`);
    }
}
