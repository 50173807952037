import { getResourcePath } from 'owa-config/lib/bootstrapOptions';
import { getItem } from 'owa-local-storage';
import { LocalStoreLocaleKey } from 'owa-localize-internal/lib/constants';

export function preloadSuiteUx() {
    return new Promise<void>(resolve => {
        preload(getResourcePath() + 'suiteux-shell/js/suiteux.shell.core.js');
        preload(getResourcePath() + 'suiteux-shell/js/suiteux.shell.plus.js');
        const locale = getItem(self, LocalStoreLocaleKey);
        if (locale) {
            preload(getResourcePath() + `suiteux-shell/strings/${locale}/shellstrings.json`);
        }
        resolve();
    });
}

function preload(url: string) {
    const link = document.createElement('link');
    link.href = url;
    link.rel = 'preload';
    link.as = url.endsWith('.js') ? 'script' : 'fetch';
    link.crossOrigin = 'anonymous';
    document.head.appendChild(link);
}
