export interface PromiseWithKey<T> {
    key: string | null; // will ignore this promise in calculating the bottleneck if undefined
    promise: Promise<T>;
}

const bottlenecks: {
    [bottleneck: string]: string | number;
} = {};
const bottleneckTimings: {
    [bottleneck: string]: string;
} = {};

export function trackBottleneck<T>(
    bottleneck: string,
    promisesWithKeys: PromiseWithKey<T>[]
): Promise<T[]> {
    const startTime = Date.now();

    for (var ii = 0; ii < promisesWithKeys.length; ii++) {
        calculateBottleneck(
            bottleneck,
            promisesWithKeys[ii].key,
            promisesWithKeys[ii].promise,
            startTime
        );
    }

    const promises = promisesWithKeys.map(p => p.promise);
    try {
        return Promise.all(promises);
    } catch (e: any) {
        const diagnosticInfo: any = {
            pk: typeof promisesWithKeys,
            pt: typeof promises,
            st: typeof self.Symbol,
            sti: typeof Symbol.iterator,
        };
        if (promisesWithKeys) {
            diagnosticInfo.pkl = promisesWithKeys.length;
        }
        if (promises) {
            diagnosticInfo.pl = promises.length;
            if (Symbol.iterator) {
                diagnosticInfo.pi = typeof promises[Symbol.iterator];
            }
        }

        e.additionalInfo = diagnosticInfo;
        throw e;
    }
}

export function calculateBottleneck<T>(
    bottleneck: string,
    key: string | null,
    promise: Promise<T>,
    startTime: number
): void {
    if (key) {
        promise
            .then(_ => {
                const duration = Date.now() - startTime;

                bottlenecks[bottleneck] = key;
                bottleneckTimings[bottleneck] ??= '';
                bottleneckTimings[bottleneck] += `${key}:${duration}|`;
            })
            .catch(() => {
                /* do nothing if it fails */
            });
    }
}

export function addBottleneck(key: string, value: string | number) {
    if (!bottlenecks[key]) {
        bottlenecks[key] = value;
    }
}

export function addBottleneckTiming(key: string, value: string) {
    if (!bottleneckTimings[key]) {
        bottleneckTimings[key] = value;
    }
}

export function getBottlenecks(rawValue = false) {
    return rawValue ? bottlenecks : JSON.stringify(bottlenecks);
}

export function getBottleneckTimings(rawValue = false) {
    return rawValue ? bottleneckTimings : JSON.stringify(bottleneckTimings);
}
