import type { MailboxInfo } from 'owa-client-types';
import { isBootFeatureEnabled } from 'owa-metatags';
import { type ApplicationSettingGroup, type ApplicationSettings } from 'owa-application-settings';

export function isOwaNetCoreApiEnabled(
    actionName: string,
    getApplicationSettingsCallback:
        | (<TGroup extends ApplicationSettingGroup>(
              group: TGroup,
              mailboxInfo?: MailboxInfo,
              dontErrorIfNotInitialized?: boolean
          ) => ApplicationSettings[TGroup])
        | undefined,
    mailboxInfo: MailboxInfo | undefined
) {
    if (actionName == 'StartupData' || actionName == 'ValidateAggregatedConfiguration') {
        // StartupData and ValidateAggregatedConfiguration need to run in the same process.
        // Thus check only boot flight (because StartupData happens at boot and ECS is not available yet) and ignore the ECS setting for these APIs.
        return isBootFeatureEnabled('fwk-netcore-api');
    }
    return isNetCoreECSEnabled(actionName, getApplicationSettingsCallback, mailboxInfo);
}

function isNetCoreECSEnabled(
    actionName: string,
    getApplicationSettingsCallback:
        | (<TGroup extends ApplicationSettingGroup>(
              group: TGroup,
              mailboxInfo?: MailboxInfo,
              dontErrorIfNotInitialized?: boolean
          ) => ApplicationSettings[TGroup])
        | undefined,
    mailboxInfo: MailboxInfo | undefined
) {
    const setting = getApplicationSettingsCallback?.(
        'NetCore',
        mailboxInfo,
        true /* dontErrorIfNotInitialized */
    );
    return (
        !setting?.disabledAPIs.includes(actionName) &&
        (setting?.enabledAPIs.includes(actionName) || setting?.enabledAPIs.includes('*'))
    );
}
