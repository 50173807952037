import type LazyModuleConfig from '../types/LazyModuleConfig';

const loadedImports: Record<string, boolean> = {};

let config: LazyModuleConfig = {
    markImportAsLoaded: (ids: string[]) => {
        for (const id of ids) {
            loadedImports[id] = true;
        }
    },
    isImportLoaded: (id: string) => loadedImports[id],
    logUsage: () => {},
    logError: () => {},
    logGreyError: async () => {},
    trace: () => {},
    // We can not import featureFlags in LazyImport because of dependency issue. Passing it as parameter.
    isFeatureEnabled: () => false,
};

export function getConfig() {
    return config;
}

export function setConfig(newConfig: LazyModuleConfig) {
    // Copy over any imports that have already loaded
    newConfig.markImportAsLoaded(Object.keys(loadedImports));

    // Replace the old config
    config = newConfig;
}

export function stopAutoGovernForBundling() {
    config.govern = undefined;
}
