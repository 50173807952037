import assert from 'owa-assert/lib/assert';
import { CoprincipalMailboxRank, getIndexerValueForMailboxInfo } from 'owa-client-types';
import { type TraceErrorObject, errorThatWillCauseAlert, trace } from 'owa-trace';
import addCoprincipalAccountMutator from '../actions/addCoprincipalAccount';
import onCoprincipalAccountAdded from '../actions/onCoprincipalAccountAdded';
import { default as getAccountBySourceId } from '../selectors/getAccountBySourceId';
import { updateCoprincipalUserIdentities } from './coprincipalUserIdentities';
import getCoprincipalAccountForIndexerValue from './getCoprincipalAccountForIndexerValue';
import { interceptAndAlertOnMailboxInfoChanges } from './interceptAndAlertOnMailboxInfoChanges';
import { updateMailboxInfo } from './updateMailboxInfo';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';

/**
 * Checks to make sure that the indexer value of the coprincipal account is unique. If the indexer
 * value is not unique this method will throw
 * @param accountSource Coprincipal account that is to be added
 */
function checkThatIndexerIsUnique(accountSource: CoprincipalAccountSource) {
    const indexer = getIndexerValueForMailboxInfo(accountSource.mailboxInfo);
    if (isPersistenceIdIndexerEnabled() && indexer !== accountSource.sourceId) {
        // When the persistence id indexer flight is enabled the indexer must always be the sourceId
        // of the coprincipal account.
        errorThatWillCauseAlert('AddAccount-IndexerMismatch');
    }

    const existingAccount = getCoprincipalAccountForIndexerValue(indexer);
    if (!!existingAccount) {
        // There is already a coprincipal account with the same indexer in the store. This account
        // should not have be added to the store and we should alert the system that there is an
        // error in the build.
        const error: TraceErrorObject = new Error('DuplicateCoprincipalIndexer');
        const diagnosticData = {
            type: accountSource.sourceType,
            existingType: existingAccount.sourceType,
            areSameType: accountSource.sourceType === existingAccount.sourceType,
            areSameSmtp:
                accountSource.mailboxInfo.mailboxSmtpAddress ===
                existingAccount.mailboxInfo.mailboxSmtpAddress,
            areSameUid:
                accountSource.mailboxInfo.userIdentity === existingAccount.mailboxInfo.userIdentity,
            areSamePersistenceId: accountSource.persistenceId === existingAccount.persistenceId,
            persistenceIndexer: isPersistenceIdIndexerEnabled(),
        };

        error.additionalInfo = diagnosticData;
        errorThatWillCauseAlert('DuplicateCoprincipalIndexer', error);
    }
}

/**
 * Preforms validation on the account source and then adds the account
 * @param accountSource
 */
export default function addCoprincipalAccount(accountSource: CoprincipalAccountSource): void {
    assert(!getAccountBySourceId(accountSource.sourceId), 'Found duplicated account in the store.');

    assert(accountSource.mailboxRank === CoprincipalMailboxRank, 'Must be a Coprincipal account');

    trace.info(
        `[source-list-store] Adding coprincipal account with boot state ${accountSource.bootState}`
    );

    accountSource = updateMailboxInfo(accountSource) as CoprincipalAccountSource;
    checkThatIndexerIsUnique(accountSource);
    addCoprincipalAccountMutator(accountSource);

    interceptAndAlertOnMailboxInfoChanges(accountSource.sourceId);
    updateCoprincipalUserIdentities();
    onCoprincipalAccountAdded(accountSource);
}
