import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootDataWorker" */ './lazyFunctions'),
    { name: 'AppBootDataWorker' }
);

// precompile schema intentionally exported non-lazy to ensure it's included in the main bundle and can kick off as early as possible
export { precompileSchema } from './actions/precompileSchema';
export { applyTombstonesToSessionData } from './actions/applyTombstonesToSessionData';
export { getOfflineSessionData } from './actions/getOfflineSessionData';
export { initializeOfflineTimezoneData } from './actions/getOfflineTimeZones';

export const lazyExecuteRequest = new LazyAction(
    lazyModule,
    m => m.lazyExecuteRequest.importAndExecute
);
export const lazyUnsubscribe = new LazyAction(lazyModule, m => m.lazyUnsubscribe.importAndExecute);
export const lazySetDisableAllRequests = new LazyAction(
    lazyModule,
    m => m.lazySetDisableAllRequests.importAndExecute
);
export const lazyFlushSyncLogs = new LazyAction(
    lazyModule,
    m => m.lazyFlushSyncLogs.importAndExecute
);
export const lazyOnManualSyncMail = new LazyAction(
    lazyModule,
    m => m.lazyOnManualSyncMail.importAndExecute
);
export const lazySaveOfflineSessionData = new LazyAction(
    lazyModule,
    m => m.lazySaveOfflineSessionData.importAndExecute
);
export const lazyGetOfflineTimeZones = new LazyAction(
    lazyModule,
    m => m.lazyGetOfflineTimeZones.importAndExecute
);
export const lazyGetOfflineTimeZoneOffsets = new LazyAction(
    lazyModule,
    m => m.lazyGetOfflineTimeZoneOffsets.importAndExecute
);
export const lazyScheduleHierarchySync = new LazyAction(
    lazyModule,
    m => m.lazyScheduleHierarchySync.importAndExecute
);
export const lazyOnManualSyncPGAL = new LazyAction(
    lazyModule,
    m => m.lazyOnManualSyncPGAL.importAndExecute
);
export const lazyOnManualSyncCalendar = new LazyAction(
    lazyModule,
    m => m.lazyOnManualSyncCalendar.importAndExecute
);
export const lazySetAccountTimeZone = new LazyAction(
    lazyModule,
    m => m.lazySetAccountTimeZone.importAndExecute
);
export const lazyApplyCalendarEventsSyncLieForRange = new LazyAction(
    lazyModule,
    m => m.lazyApplyCalendarEventsSyncLieForRange.importAndExecute
);
export const lazyLoadOfflineSettings = new LazyAction(
    lazyModule,
    m => m.lazyLoadOfflineSettings.importAndExecute
);
export const lazySaveOfflineSettings = new LazyAction(
    lazyModule,
    m => m.lazySaveOfflineSettings.importAndExecute
);
export const lazyInvalidateOfflineConversationNodes = new LazyAction(
    lazyModule,
    m => m.lazyInvalidateOfflineConversationNodes.importAndExecute
);
export const lazyLogPeopleDataToSyncLogs = new LazyAction(
    lazyModule,
    m => m.lazyLogPeopleDataToSyncLogs.importAndExecute
);
