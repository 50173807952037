import { logStartGreyError } from 'owa-analytics-start';
import { setIsDataWorkerFaulted, isGulpOrBranching } from 'owa-config';
import { isDataWorkerPlatformSupported } from 'owa-data-worker-check/lib/isDataWorkerPlatformSupported';
import type { SyncEvent } from 'owa-offline-sync-diagnostics';
import { recordSyncEvents } from 'owa-offline-sync-diagnostics-client';
import { debugErrorThatWillShowErrorPopupOnly, errorThatWillCauseAlert } from 'owa-trace';
import OwaWorker from 'owa-worker-shared/lib/Worker';
import { isCriticalWorkerError } from 'owa-worker-shared/lib/utils/isCriticalWorkerError';

let worker: Worker | undefined;

const WorkerPrefix = 'DataWorker';

interface WorkerErrorEvent extends ErrorEvent {
    name: string;
    stack?: string;
}

export function loadDataWorker(): Worker | undefined {
    if (!worker && isDataWorkerPlatformSupported()) {
        worker = new OwaWorker('owa.worker.data.js', {
            name: 'DataWorker',
        }) as Worker;

        worker.onerror = onError;
    }

    return worker;
}

function onError(ev: ErrorEvent) {
    if (isCriticalWorkerError(ev)) {
        setIsDataWorkerFaulted(ev as any);
        logStartGreyError('worker_init_error', ev.error);
        const syncEvent: SyncEvent = {
            type: 'EngineStateChanged',
            data: ['Worker Fault: ' + ev.message],
        };
        recordSyncEvents([syncEvent]);

        // popup error for devs and branch users to make worker breaks more obvious
        // there can be hash at the end of the script name
        if (isGulpOrBranching() && ev.message?.includes('owa.worker.data.')) {
            debugErrorThatWillShowErrorPopupOnly(
                'DataWorker entry script failed to execute. Offline features will not be available.  Check console errors for more details.'
            );
        }
    } else {
        const workerError = ev as WorkerErrorEvent;
        workerError.name = WorkerPrefix;
        let message = workerError.message;
        const newlineIndex = message?.indexOf('\n');
        if (newlineIndex > 0) {
            workerError.stack = message.substring(newlineIndex + 1);
            message = message.substring(0, newlineIndex);
        }
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert(WorkerPrefix + ':' + message, workerError.error ?? workerError);
    }
}
