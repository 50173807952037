import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';

type ScheduleOptions = {
    priority: 'background' | 'user-visible' | 'user-blocking';
    delay?: number;
    signal?: AbortSignal;
};

export function schedule<T extends () => any>(
    task: T,
    options?: number | ScheduleOptions
): Promise<Awaited<ReturnType<T>>> {
    const isSimpleOption = typeof options === 'number' || typeof options === 'undefined';
    const delay = isSimpleOption ? options : options?.delay;
    const priority = isSimpleOption ? 'background' : options?.priority;
    const signal = isSimpleOption ? undefined : options?.signal;

    if (typeof self.scheduler?.postTask === 'function') {
        return self.scheduler.postTask(task, {
            priority,
            delay,
            signal,
        });
    } else {
        return new Promise((resolve, reject) => {
            const useIdleCallback =
                typeof delay === 'undefined' &&
                priority === 'background' &&
                typeof self.requestIdleCallback === 'function';

            let timer: number;
            if (signal) {
                if (signal.aborted) {
                    reject(signal.reason);
                    return;
                }

                signal.addEventListener('abort', abort);

                function abort() {
                    if (useIdleCallback) {
                        self.cancelIdleCallback(timer);
                    } else {
                        self.clearTimeout(timer);
                    }
                    reject(signal?.reason);
                    signal?.removeEventListener('abort', abort);
                }
            }
            if (useIdleCallback) {
                timer = self.requestIdleCallback(completer);
            } else {
                timer = self.setTimeout(completer, delay);
            }

            function completer() {
                if (signal?.aborted) {
                    reject(signal.reason);
                    return;
                }
                // we need to wrap the task in a try-catch block to ensure that it throws inside of the promise
                // otherwise setTimeout will throw to the global scope
                try {
                    resolve(task());
                } catch (e) {
                    reject(e);
                }
            }
        });
    }
}

export async function yieldNow(source: string) {
    /* eslint-disable-next-line no-restricted-properties --
     * This function is a wrapper for yield. All other usage should go through here. */
    if (typeof self.scheduler?.yield === 'function') {
        /* eslint-disable-next-line no-restricted-properties --
         * This function is a wrapper for yield. All other usage should go through here. */
        await self.scheduler.yield();
    } else {
        await schedule(() => {}, { priority: 'user-blocking' });
    }
    addToTimingMap('yieldNow', source);
}

export async function yieldIdle(source: string) {
    if (self.requestIdleCallback) {
        await new Promise(resolve => self.requestIdleCallback(resolve));
        addToTimingMap('yieldIdle', source);
    } else {
        await yieldNow(source);
    }
}
