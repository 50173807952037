type SessionStage = 'PLT' | 'TTI' | 'Governor' | 'LongSession';

let sessionStage: SessionStage = 'PLT';

export function getSessionStage(): SessionStage {
    return sessionStage;
}

export function markPLTFinished(): void {
    sessionStage = 'TTI';
}

export function markTTIFinished(): void {
    sessionStage = 'Governor';
}

export function markGovernorFinished(): void {
    sessionStage = 'LongSession';
}
