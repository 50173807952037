import type { CustomData, DatapointOptions } from 'owa-analytics-types';
import { addToAnnotationMap } from './utils/timingMap';

export function wrapForcedLayout<T>(source: string, forceLayoutFunc: () => T): T {
    const start = performance.now();

    // run the code that will cause the force layout
    const result = forceLayoutFunc();

    const duration = Math.floor(performance.now() - start);

    // the forced layout is only interesting if it took some time
    if (duration > 0) {
        // add annotation to map so we can correlate with LOAF
        addToAnnotationMap('ForcedLayout', source, start);
        logDatapointfunc?.('ForcedLayout', { source, duration }, { ring: 'Dogfood' });
    }

    return result;
}

// inverting the depedency to avoid circular depedency in packages
type LogDatapointFunc = (
    eventName: string,
    customData?: CustomData,
    options?: DatapointOptions
) => void;
let logDatapointfunc: LogDatapointFunc | undefined;

export function setLogDatapointFunc(func: LogDatapointFunc) {
    logDatapointfunc = func;
}
