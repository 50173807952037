import type { PerformanceDatapointType } from 'owa-analytics-types';
import { markFunction } from './markFunction';
import { calculateBottleneck } from './bottlenecks';

const pendingDatapoints: PerformanceDatapointType[] = [];

export function resolvePendingDatapoints() {
    for (const datapoint of pendingDatapoints) {
        datapoint.resolvePendingCallbacks();
    }
}

export function addPendingDatapoint(datapoint: PerformanceDatapointType) {
    pendingDatapoints.push(datapoint);
}

let pltPromise = Promise.resolve();
export const getPltPromise = markFunction(() => pltPromise, 'postren');

export type PltBlocker = 'ribbon' | 'suiteux';

// This function is used to block the PLT
// until the returned callback is called
export function blockPlt(source: PltBlocker) {
    let resolve: () => void;
    const blockingPromise = new Promise<void>(r => {
        resolve = r;
    });
    calculateBottleneck('postren', source, blockingPromise, Date.now());
    pltPromise = pltPromise.then(() => blockingPromise);
    return () => {
        resolve?.();
    };
}
