import { wrapForcedLayout } from 'owa-performance';
import { getWindowData } from 'owa-window-data';
// we will cache the height of the page to avoid layouts
let cachedBrowserHeight: number | undefined;
export function getBrowserHeight(skipCache?: boolean): number {
    /**
     * The reason we are getting min of height here is because we have seen cases
     * where the window.innerHeight is not ready (and the value is bigger than the actual window height)
     * by the time we want to calculate the available height.
     * So far documentElement.clientHeight is showing correct value, but we are not switching to relying totally
     * on it as this is the way the browser height is being calculated.
     */
    var windowData = getWindowData();
    var doc = windowData.document;
    if (!cachedBrowserHeight || skipCache) {
        wrapForcedLayout('getBrowserHeight', () => {
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * This is a helper function that will cache the value to reduce the number of forced re-layouts
             *	> 'clientHeight' is restricted from being used. This property can cause performance problems by causing re-layouts. Please use a resize observer instead. (https://aka.ms/OWALintWiki)*/
            const documentElementClientHeight = doc?.documentElement?.clientHeight;
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * This is a helper function that will cache the value to reduce the number of forced re-layouts
             *	> 'innerHeight' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. (https://aka.ms/OWALintWiki)*/
            const windowInnerHeight = windowData.innerHeight;
            cachedBrowserHeight =
                windowInnerHeight && documentElementClientHeight
                    ? Math.min(windowInnerHeight, documentElementClientHeight)
                    : /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
                       * This is a helper function that will cache the value to reduce the number of forced re-layouts
                       *	> 'clientHeight' is restricted from being used. This property can cause performance problems by causing re-layouts. Please use a resize observer instead. (https://aka.ms/OWALintWiki)*/
                      windowInnerHeight || documentElementClientHeight || doc?.body?.clientHeight;
        });
    }
    return cachedBrowserHeight || 0;
}
