import { stringify, getQueryStringParameters } from 'owa-querystring';
import { type OwaWebServerAuthState } from './types/OwaWebServerAuthState';
import { getHostLocation } from './getHostLocation';
import type { MailboxInfo } from 'owa-client-types';

let isErrorAlreadyHandled: boolean = false;
export function doAuthRedirect(headers?: Headers, mailboxInfo?: MailboxInfo) {
    if (!isErrorAlreadyHandled) {
        isErrorAlreadyHandled = true;
        const loc = getHostLocation();
        const query = getQueryStringParameters(loc);
        let needsRedirect = false;

        const claimsChallengeQuery = headers ? headers.get('X-OWA-ClaimChallenge') : undefined;
        if (claimsChallengeQuery && query.cc !== claimsChallengeQuery) {
            query.cc = decodeURIComponent(claimsChallengeQuery);
            needsRedirect = true;
        }

        if (query.authRedirect === undefined) {
            query.authRedirect = 'true';
            needsRedirect = true;
        }

        if (mailboxInfo) {
            query.login_hint = mailboxInfo.userIdentity
                ? mailboxInfo.userIdentity
                : mailboxInfo.mailboxSmtpAddress;
        }

        if (needsRedirect) {
            query.state = '0';
            loc.search = '?' + stringify(query);
        }
    }
}

export function test_reset() {
    isErrorAlreadyHandled = false;
}
