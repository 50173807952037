import type MailboxInfo from './MailboxInfo';
import type MailboxInfoSource from './MailboxInfoSource';
import isMailboxProvider from './isMailboxProvider';
import isMailboxRank from './isMailboxRank';

export default function isMailboxInfoSource(
    mailboxInfo?: MailboxInfo
): mailboxInfo is MailboxInfoSource {
    return (
        typeof mailboxInfo?.sourceId === 'string' &&
        isMailboxRank(mailboxInfo?.mailboxRank) &&
        isMailboxProvider(mailboxInfo?.mailboxProvider)
    );
}
