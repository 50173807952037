import { wrapFunction } from './wrapFunction';
import { shouldTrackBootQueryStringParam, addBootTiming } from './trackBootTimings';
import { hasQueryStringParameter } from 'owa-querystring';
import { addPASKeyMetric } from './pasTiming';

export function markFunction<TFunc extends (...args: any[]) => any>(
    func: TFunc,
    name: string
): (...args: Parameters<TFunc>) => ReturnType<TFunc> {
    return wrapFunction(
        func,
        () => markStart(name),
        () => markEnd(name)
    );
}

export function markStart(name: string) {
    addBootTiming(`${name}_s`);
}

export function markEnd(name: string) {
    const endName = `${name}_e`;
    addBootTiming(endName);
    if (hasQueryStringParameter(shouldTrackBootQueryStringParam)) {
        const measure = self.performance.measure(name, `${name}_s`, endName);

        addPASKeyMetric(measure.name, measure.duration);
    }
}
