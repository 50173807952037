import type { LazyModuleOptions } from './LazyModule';
import LazyModule from './LazyModule';

export class LazyBootModule<TModule> extends LazyModule<TModule> {
    constructor(
        importCallback: () => Promise<TModule>,
        lazyModuleOptions: LazyModuleOptions<TModule>
    ) {
        lazyModuleOptions.runWhen = func => func();

        super(importCallback, lazyModuleOptions);
    }
    // this method is needed even if it is not called so LazyBootModule
    // has a different Signature than LazyModule
    public isBootModule = () => true;
}
