import { logStartGreyError } from 'owa-analytics-start';
import { updateDiagnosticsOnResponse } from 'owa-config/lib/envDiagnostics';
import { markFunction, addBottleneck, addBootCustomData, addBootTiming } from 'owa-performance';
import { getConfig } from 'owa-service/lib/config';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { canSetHeaderISO8859Safe, getAndProcessSessionData } from 'owa-shared-startupdata';
import { updateDiagnosticsOnSessionData } from './sessionDiagnostics';
import { getItem, setItem, removeItem, type LocalStorageKeys } from 'owa-local-storage';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import type { FetchDataErrorHandler } from 'owa-shared-start-types';
import createBootError from './createBootError';
import { createStatusErrorMessage } from './createStatusErrorMessage';
import { onBeforeRetry } from './onBeforeRetry';
import { isBootFeatureEnabled } from 'owa-metatags';

const FindFolderParametersKey = 'sdfp';
const FindMessageParametersKey = 'sdmp';
const GetCalendarViewParametersKey = 'gcvp';

enum LocalStorageFunnel {
    NoLocalStorage = 0,
    LocalStorageFound = 1,
    ApiFoundParams = 2,
    ApiUsedParams = 3,
}

// Keep in sync with values in the service
// /sources/dev/clients/src/Owa2/Server/Web/StartupDiagnostics.cs
enum StartupDiagnostics {
    None = 0,
    FoundFolderParams = 1,
    FoundMessageParams = 2,
    UsedFolderParams = 4,
    UsedMessageParams = 8,
    JsonException = 16,
    FoundCalendarViewParams = 32,
    UsedCalendarViewParams = 64,
}

let findFolderTunnel: LocalStorageFunnel = LocalStorageFunnel.NoLocalStorage;
let findMessageTunnel: LocalStorageFunnel = LocalStorageFunnel.NoLocalStorage;
let getCalendarViewTunnel: LocalStorageFunnel = LocalStorageFunnel.NoLocalStorage;

/**
 * Getting the session data will need access to the boot error functionality, the
 * FetchDataErrorHandler type is used so that we can pass in these dependencies to
 * the code that gets the startup data.
 * @returns FetchDataErrorHandler with callbacks to access the boot error functionality
 */
export function getSessionDataErrorHandler(): FetchDataErrorHandler {
    return {
        createBootError,
        createStatusErrorMessage,
        onBeforeRetry,
    };
}

export default markFunction(getSessionData, 'sd');
function getSessionData(authToken?: string): Promise<SessionData> {
    const headers = new Headers();

    // If we are in Monarch the account source list store is initialized and we can get
    // the MailboxInfo for the global settings account. If we are in OWA the account
    // source list store is not initialized until after the session data has been
    // retrieved and so we will need to pass in undefined
    const config = getConfig();
    const mailboxInfo = config.getGlobalSettingsAccountMailboxInfo
        ? config.getGlobalSettingsAccountMailboxInfo()
        : undefined;

    const folderParams = getItem(window, FindFolderParametersKey);
    if (folderParams && canSetHeaderISO8859Safe('folderParams', folderParams)) {
        headers.set('folderParams', folderParams);
        findFolderTunnel = LocalStorageFunnel.LocalStorageFound;
    }

    const messageParams = getItem(window, FindMessageParametersKey);
    if (messageParams && canSetHeaderISO8859Safe('messageParams', messageParams)) {
        headers.set('messageParams', messageParams);
        findMessageTunnel = LocalStorageFunnel.LocalStorageFound;
    }

    const calendarViewParams = getItem(window, GetCalendarViewParametersKey);
    if (calendarViewParams && canSetHeaderISO8859Safe('calendarViewParams', calendarViewParams)) {
        headers.set('calendarViewParams', calendarViewParams);
        getCalendarViewTunnel = LocalStorageFunnel.LocalStorageFound;
        addBootCustomData('getCalendarViewParams', calendarViewParams);
    }

    const shouldReturnLinkedCalendarEntryV2 = isBootFeatureEnabled(
        'cal-enable-HybridModel-for-calendarSharing'
    );
    const includeOrganizationCalendars = isBootFeatureEnabled('cal-organizationCalendars');
    if (shouldReturnLinkedCalendarEntryV2 || includeOrganizationCalendars) {
        const calendarFoldersParams = JSON.stringify({
            ShouldReturnLinkedCalendarEntryV2: shouldReturnLinkedCalendarEntryV2,
            IncludeOrganizationCalendars: includeOrganizationCalendars,
        });
        if (
            calendarFoldersParams &&
            canSetHeaderISO8859Safe('calendarFoldersParams', calendarFoldersParams)
        ) {
            headers.set('calendarFoldersParams', calendarFoldersParams);
        }
    }

    if (authToken) {
        headers.set('Authorization', authToken);
    }

    return getAndProcessSessionData(
        mailboxInfo,
        headers,
        getSessionDataErrorHandler(),
        postProcess,
        processHeaders,
        updateDiagnosticsOnResponse
    );
}

export function checkUserAndLogonEmail(settings: SessionData) {
    // Confirm that the session data returned by the StartupData call contains
    // non-empty User and Logon email addresses.
    if (!settings.owaUserConfig?.SessionSettings?.UserEmailAddress) {
        const errorMessage = 'AcctInit-NoUserEmailAddress';
        const e = new Error(errorMessage);
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(errorMessage, e, {
            st: typeof settings,
            uc: typeof settings.owaUserConfig,
            ss: typeof settings.owaUserConfig?.SessionSettings,
            ue: typeof settings.owaUserConfig?.SessionSettings?.UserEmailAddress,
        });
    }

    if (!settings.owaUserConfig?.SessionSettings?.LogonEmailAddress) {
        const errorMessage = 'AcctInit-NoLogonEmailAddress';
        const e = new Error(errorMessage);
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        logStartGreyError(errorMessage, e, {
            st: typeof settings,
            uc: typeof settings.owaUserConfig,
            ss: typeof settings.owaUserConfig?.SessionSettings,
            ue: typeof settings.owaUserConfig?.SessionSettings?.LogonEmailAddress,
        });
    }
}

function postProcess(settings: SessionData) {
    checkUserAndLogonEmail(settings);

    settings.folderParams &&
        setItem(window, FindFolderParametersKey, JSON.stringify(settings.folderParams));
    settings.messageParams &&
        setItem(window, FindMessageParametersKey, JSON.stringify(settings.messageParams));
    settings.calendarViewParams &&
        setItem(window, GetCalendarViewParametersKey, JSON.stringify(settings.calendarViewParams));
    const diagnostics = updateDiagnosticsOnSessionData(settings);
    setItem(window, 'BootDiagnostics', JSON.stringify(diagnostics));

    return settings;
}

function processHeaders(headers: HeadersWithoutIterator) {
    let startupDiagnostics: StartupDiagnostics | undefined;
    if (headers) {
        const preloadHeader = headers.get('x-owa-startup-preload');
        if (preloadHeader) {
            addBottleneck('sd_pr', preloadHeader);
        }
        const diagnostics = headers.get('x-owa-startup-diag');
        if (diagnostics) {
            startupDiagnostics = parseInt(diagnostics);
        }
    }
    addLocalStorageBottleneck(
        FindFolderParametersKey,
        findFolderTunnel,
        startupDiagnostics,
        StartupDiagnostics.UsedFolderParams,
        StartupDiagnostics.FoundFolderParams
    );
    addLocalStorageBottleneck(
        FindMessageParametersKey,
        findMessageTunnel,
        startupDiagnostics,
        StartupDiagnostics.UsedMessageParams,
        StartupDiagnostics.FoundMessageParams
    );
    addLocalStorageBottleneck(
        GetCalendarViewParametersKey,
        getCalendarViewTunnel,
        startupDiagnostics,
        StartupDiagnostics.UsedCalendarViewParams,
        StartupDiagnostics.FoundCalendarViewParams
    );
    try {
        const beStart = headers.get('x-backend-begin');
        const beEnd = headers.get('x-backend-end');
        if (beStart && beEnd) {
            addBootTiming('sdbe', new Date(beEnd).getTime() - new Date(beStart).getTime());
        }
    } catch {
        // no op
    }
}

function addLocalStorageBottleneck(
    bottleneck: LocalStorageKeys,
    funnel: LocalStorageFunnel,
    diagnostics: StartupDiagnostics | undefined,
    useParams: StartupDiagnostics,
    foundParams: StartupDiagnostics
) {
    if (diagnostics) {
        if ((diagnostics & useParams) != 0) {
            funnel = LocalStorageFunnel.ApiUsedParams;
        } else if ((diagnostics & foundParams) != 0) {
            funnel = LocalStorageFunnel.ApiFoundParams;
        }

        // if there was a json exception in startupdata then local storage is not valid so
        // let's delete it so we don't use it next time
        if ((diagnostics & StartupDiagnostics.JsonException) != 0) {
            removeItem(window, bottleneck);
        }
    }
    addBottleneck(bottleneck, funnel);
}
