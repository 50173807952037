import './bootstrapWebpack'; // Important that this come first, to bootstrap webpack settings
import 'owa-polyfills';
import patchMobX from 'owa-configure-mobx/lib/patchMobX';
import { configureMobX } from 'owa-configure-mobx/lib/configureMobX';

import { patchNode } from './patchNode';
// Configure MobX; this needs to happen ahead of other imports since Satchel stores are created as
// a side effect of importing
configureMobX();

// Monkey-patch MobX to proxy stale objects to the store
patchMobX();

// Monkey-patch DOM node APIs to avoid some component errors
patchNode();
