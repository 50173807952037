import { addResponseTiming } from './addWorkerTiming';

const workerMessageMetadata = new WeakMap<Worker, Map<string, string>>();

export function patchWorker(worker: Worker) {
    if (workerMessageMetadata.has(worker)) {
        return;
    }
    workerMessageMetadata.set(worker, new Map<string, string>());

    const workerAddEventListener = worker.addEventListener;
    worker.addEventListener = function (
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions
    ) {
        if (type === 'message') {
            workerAddEventListener.call(
                this,
                type,
                function (this: Worker, event: Event) {
                    try {
                        const messageEvent = event as MessageEvent;
                        if (messageEvent.data) {
                            const id = (event as MessageEvent).data.id;
                            if (id) {
                                const metadata = workerMessageMetadata.get(this);
                                if (metadata && id) {
                                    addResponseTiming(metadata.get(id) ?? 'unknownId');
                                    metadata.delete(id);
                                }
                            }
                        }
                    } catch {}
                    if (typeof listener === 'function') {
                        return listener.call(this, event);
                    } else {
                        return listener.handleEvent(event);
                    }
                },
                options
            );
        } else {
            workerAddEventListener.call(this, type, listener, options);
        }
    };

    const workerPostMessage = worker.postMessage;
    worker.postMessage = function (message: any, transfers: any) {
        try {
            if (message && message.id) {
                const metadata = workerMessageMetadata.get(this);
                if (metadata && message.path) {
                    let tag = message.path[0] ?? 'unknownFunction';
                    if (tag === 'execute') {
                        const operation = message.argumentList?.[0]?.value?.operationName;
                        tag += `:${operation ?? 'unknownQuery'}`;
                    }

                    metadata.set(message.id, tag);
                }
            }
        } catch {}
        workerPostMessage.call(this, message, transfers);
    };
}
