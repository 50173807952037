import type { MailboxInfo } from 'owa-client-types';
import { type HttpStatusCode } from 'owa-http-status-codes';
import type { HeadersWithoutIterator, RequestOptions } from 'owa-service/lib/RequestOptions';
import { makeServiceRequest } from 'owa-service/lib/ServiceRequest';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { BootError, FetchDataErrorHandler } from 'owa-shared-start-types';
import { getStartupDataEndpoint } from './getStartupDataEndpoint';
import { registerCreateServiceResponseCallback } from 'owa-service/lib/fetchWithRetry';
import { handleServiceResponseCallbackForPolicySettingsLog } from 'owa-account-user-configuration-monitor/lib/handleServiceResponseCallbackForPolicySettingsLog';
import isRetriableAuthError from 'owa-service/lib/isRetriableAuthError';
import getUrlWithAddedQueryParameters from 'owa-url/lib/getUrlWithAddedQueryParameters';

export function overrideIsRetriableStatus(status: number): boolean {
    return (
        status == 0 ||
        // 204 is returned during JIT with OwaInvalidUserLanguageException
        status == 204 ||
        status == 404 ||
        status == 408 ||
        status == 449 ||
        // explicitly add 507 which is returned when consumer mailbox is not provisioned
        status == 507 ||
        status >= 500 ||
        isRetriableAuthError(status)
    );
}

let hasReigsteredServiceResponseCallbackForPolicySettingsLogs = false;
function registerServiceResponseCallbackForPolicySettingsLogs() {
    if (!hasReigsteredServiceResponseCallbackForPolicySettingsLogs) {
        hasReigsteredServiceResponseCallbackForPolicySettingsLogs = true;
        registerCreateServiceResponseCallback(
            async (responsePromise, actionName, _url, _attemptCount, optionsPromise) => {
                try {
                    const response = await responsePromise;
                    const responseCloneFunc = response.clone;
                    const responseClone =
                        typeof responseCloneFunc == 'function' ? response.clone() : undefined;

                    if (responseClone) {
                        handleServiceResponseCallbackForPolicySettingsLog(
                            responseClone,
                            actionName,
                            optionsPromise
                        );
                    }
                } catch (e) {
                    // there's an error in cloning the response, ignore it.
                }
            }
        );
    }
}

export function fetchData(
    mailboxInfo: MailboxInfo | undefined,
    headers: HeadersWithoutIterator,
    errorHandler: FetchDataErrorHandler,
    postProcessFunction: (json: SessionData) => SessionData,
    processHeaders: (headers: HeadersWithoutIterator) => void,
    updateDiagnosticsOnReponseFunction: (response: Response) => void
): Promise<SessionData> {
    const endpoint = getStartupDataEndpoint();

    // it's helpful to have some page startup params in the url for the initial request, for things like
    // bO (cache bypass on the server), plus (for adding substrate features), and minus (for removing substrate features)
    const firstEndpoint = tryAddStartupParams(endpoint);

    registerServiceResponseCallbackForPolicySettingsLogs();

    const options: RequestOptions = {
        endpoint: firstEndpoint,
        headers,
        returnFullResponseOnSuccess: true,
        authNeededOnUnAuthorized: false,
        mailboxInfo,
        shouldRetry: async (status: number, res: Response) => {
            // we want to retry if the status is not a 200
            if (overrideIsRetriableStatus(status)) {
                return true;
            }
            // or if the status is 200 but the response can not be parsed into json
            if (status == 200 && !(await canParseJson(res))) {
                return true;
            }

            return false;
        },
        onBeforeRetry: response => errorHandler.onBeforeRetry(endpoint, response),
        retryCount: 3,
        scenarioId: 'accountPolicy',
    };

    return makeServiceRequest<Response>('StartupData', undefined, options)
        .then(
            (response: Response) => {
                // Before validating the response allow collecting diagnostic for the response
                updateDiagnosticsOnReponseFunction(response);
                if (!response) {
                    throw new Error('NoResponse');
                }
                // it is important that we check for not a status of 200 instead of !response.ok since
                // OwaInvalidUserLanguageException returns with a status of 204
                if (response.status != 200) {
                    throw errorHandler.createStatusErrorMessage(response);
                }

                processHeaders(response.headers);

                return response.json().catch(e => {
                    const invalidJsonError: BootError = new Error('InvalidJson');
                    const errorType = typeof e;
                    invalidJsonError.additionalInfo = {
                        error: errorType == 'string' ? e : e?.message || errorType,
                        online: self?.navigator?.onLine,
                    };
                    throw invalidJsonError;
                });
            },
            response => {
                if (response instanceof Error) {
                    throw response;
                } else {
                    throw errorHandler.createStatusErrorMessage(response);
                }
            }
        )
        .then(postProcessFunction)
        .catch(e => Promise.reject(errorHandler.createBootError(e, 'StartupData', endpoint, 0)));
}

async function canParseJson(response: Response): Promise<boolean> {
    try {
        await response.clone().json();
        return true;
    } catch {
        return false;
    }
}

function tryAddStartupParams(path: string): string {
    try {
        const params = getStartupParams();
        if (params) {
            path = getUrlWithAddedQueryParameters(path, params, true);
        }
    } catch (e) {}

    return path;
}

function getStartupParams(): {
    [key: string]: string;
} {
    const params = new URLSearchParams(self.location.search);
    const allowedKeys = ['bO', 'plus', 'minus', 'bfs'];
    const result: {
        [key: string]: string;
    } = {};

    for (const key of allowedKeys) {
        if (params.has(key)) {
            result[key] = params.get(key) as string;
        }
    }

    return result;
}
