let workerScriptTrustedTypesPolicy: Pick<TrustedTypePolicy, 'createScriptURL'> | undefined;
if (self.trustedTypes) {
    workerScriptTrustedTypesPolicy = self.trustedTypes.createPolicy(
        'workerScriptTrustedTypesPolicy',
        {
            createScriptURL: (url: string) => url,
        }
    );
}

export { workerScriptTrustedTypesPolicy };
