import { trace } from 'owa-trace';
import { mutatorAction } from 'satcheljs';
import getAccountSourceListStore from '../store/accountSourceListStore';
import type { PolicyStateTypeEnum } from 'owa-account-source-list-types';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';

export default mutatorAction(
    'SET_COPRINCIPAL_ACCOUNT_POLICY_STATE',
    function (sourceId: string, state: PolicyStateTypeEnum | undefined): void {
        const account = getAccountSourceListStore().sources.find(
            thisAccount =>
                accountRankTypeChecker.isCoprincipal(thisAccount) &&
                thisAccount.sourceId === sourceId
        );
        if (account) {
            account.policyState = state;
            trace.info(
                `[source-list-store] Setting coprincipal account policy state to ${account.policyState}`
            );
        }
    }
);
