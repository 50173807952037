import {
    ContractsSupportedFilter,
    StartupFilter,
    default as getCoprincipalAccounts,
} from '../selectors/getCoprincipalAccounts';
import type { AccountKey, MailboxInfo } from 'owa-client-types';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';

/**
 * This enum indicates the current state of the account. This is used to filter accounts you want.
 *
 * Check out this Wiki page for more information on the different states of an account:
 * https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/14947/Types-of-Accounts
 */
export enum AccountStatus {
    /**
     * An account that is currently starting.
     * Accounts that are straring may NOT have the following loaded:
     * - feature flags
     * - app settings
     * - session store data
     *
     * (This is the same as StartupFilter.Starting which includes completed and errored accounts)
     */
    Starting,

    /**
     * An account that has completely initialized.
     * Accounts that are complets HAVE the following loaded:
     * - feature flags
     * - app settings
     * - session store data
     *
     * (This is the same as StartupFilter.Completed)
     */
    Completed,

    /**
     * An account that encountered an error during startup.
     * Errored accounts may not have the following loaded:
     * - feature flags
     * - app settings
     * - session store data
     *
     * (This is the same as StartupFilter.Error)
     */
    Error,

    /**
     * Return coprincipal accounts that are Starting, Completed, or Error (basically
     * this returns all the coprincipal accounts).
     *
     * !!!BEWARE!!! Only accounts that are COMPLETE are guaranteed to have the following loaded:
     * - feature flags
     * - app settings
     * - session store data
     *
     * (This is the same as StartupFilter.StartingOrCompleteOrError)
     */
    Any,
}

/**
 * Indicates which app you want to get accounts for. It is important to filter accounts as different accounts support different features.
 *
 * Check out this WIki page for more information on what accounts support what modules:
 * https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/14947/Types-of-Accounts
 */
export enum App {
    /**
     * Returns all accounts regardless of what modules they support.
     */
    All,

    /**
     * Returns all accounts that support the Calendar Module.
     */
    Calendar,

    /**
     * Returns all accounts that support the Contacts Module.
     */
    Contacts,

    /**
     * Returns all accounts that support the Mail Module.
     */
    Mail,

    /**
     * Returns all accounts that support the Settings Module
     */
    Settings,
}

/**
 * Gets a list of accountKeys for the given account status and app.
 *
 * Not all accounts are initialized fully. Please note that:
 * - AccountStatus.Any will return accounts that are starting, completed, or errored.
 * - AccountStatus.Completed will return accounts that have completed loading.
 *
 * Not all accounts support all apps. Please note that:
 * - App.All will return accounts that support any app.
 *
 * Check out the Wiki page for more information on what each account status means and what accounts support what modules:
 * https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/14947/Types-of-Accounts
 * @param accountStatus Gets the accounts with on the given status.
 * @param relatedModule Gets the accounts that support the given app.
 * @returns returns the mailboxInfos for the given account status and app.
 */
export function getAccountKeys(accountStatus: AccountStatus, relatedModule: App): AccountKey[] {
    return getAccounts(accountStatus, relatedModule).map(account =>
        getAccountKeyForMailboxInfo(account.mailboxInfo)
    );
}

/**
 * Gets a list of mailboxInfos for the given account status and app.
 *
 * Not all accounts are initialized fully. Please note that:
 * - AccountStatus.Any will return accounts that are starting, completed, or errored.
 * - AccountStatus.Completed will return accounts that have completed loading.
 *
 * Not all accounts support all apps. Please note that:
 * - App.All will return accounts that support any app.
 *
 * Check out the Wiki page for more information on what each account status means and what accounts support what modules:
 * https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/14947/Types-of-Accounts
 * @param accountStatus Gets the accounts with on the given status.
 * @param relatedModule Gets the accounts that support the given app.
 * @returns returns the mailboxInfos for the given account status and app.
 */
export function getMailboxInfos(accountStatus: AccountStatus, relatedModule: App): MailboxInfo[] {
    return getAccounts(accountStatus, relatedModule).map(account => account.mailboxInfo);
}

/**
 * Gets a list of Accounts for the given account status and app.
 *
 * Not all accounts are initialized fully. Please note that:
 * - AccountStatus.Any will return accounts that are starting, completed, or errored.
 * - AccountStatus.Completed will return accounts that have completed loading.
 *
 * Not all accounts support all apps. Please note that:
 * - App.All will return accounts that support any app.
 *
 * Check out the Wiki page for more information on what each account status means and what accounts support what modules:
 * https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/14947/Types-of-Accounts
 * @param accountStatus Gets the accounts with on the given status.
 * @param relatedModule Gets the accounts that support the given app.
 * @returns returns the mailboxInfos for the given account status and app.
 */
export function getAccounts(
    accountStatus: AccountStatus,
    relatedModule: App
): CoprincipalAccountSource[] {
    return getCoprincipalAccounts(
        accountStatusToStartupFilter(accountStatus),
        appToContractsSupportedFilter(relatedModule)
    );
}

// Internal function to convert the AccountStatus enum to the StartupFilter enum
function accountStatusToStartupFilter(accountStatus: AccountStatus): StartupFilter {
    switch (accountStatus) {
        case AccountStatus.Starting:
            return StartupFilter.Starting;
        case AccountStatus.Completed:
            return StartupFilter.Completed;
        case AccountStatus.Error:
            return StartupFilter.Error;
        case AccountStatus.Any:
            return StartupFilter.StartingOrCompleteOrError;
    }
}

// Internal function to convert the App enum to the ContractsSupportedFilter enum
function appToContractsSupportedFilter(app: App): ContractsSupportedFilter {
    switch (app) {
        case App.All:
            return ContractsSupportedFilter.Any;
        case App.Calendar:
            return ContractsSupportedFilter.Calendar;
        case App.Contacts:
            return ContractsSupportedFilter.Contacts;
        case App.Mail:
            return ContractsSupportedFilter.Mail;
        case App.Settings:
            return ContractsSupportedFilter.Settings;
    }
}
