import type MailboxInfo from './MailboxInfo';
import getAccountKeyForMailboxInfo from './getAccountKeyForMailboxInfo';
import isSameCoprincipalAccountByAccountKeys from './isSameCoprincipalAccountByAccountKeys';

/**
 * This function will validate and compare the account keys of the two mailboxInfos
 * to let us know if they are of the same coprincipal account.
 *
 * A direct comparison of the user email addresses or userIdentity is no longer
 * sufficient as the same user can have multiple accounts.
 *
 * @param mailboxInfo1 1st mailbox you want to compare. You can pass in undefined for ease of use and it will return false.
 * @param mailboxInfo2 2nd mailbox you want to compare. You can pass in undefined for ease of use and it will return false.
 * @returns true if the two mailboxInfos are valid and of the same coprincipal account.
 */
export default function isSameCoprincipalAccountMailboxInfos(
    mailboxInfo1: MailboxInfo | undefined | null,
    mailboxInfo2: MailboxInfo | undefined | null
): boolean {
    let isSame = false;

    if (mailboxInfo1 && mailboxInfo2) {
        const mailbox1AccountKey = getAccountKeyForMailboxInfo(mailboxInfo1);
        const mailbox2AccountKey = getAccountKeyForMailboxInfo(mailboxInfo2);

        isSame = isSameCoprincipalAccountByAccountKeys(mailbox1AccountKey, mailbox2AccountKey);
    }

    return isSame;
}
