import { isBootFeatureEnabled } from 'owa-metatags';
export function getOwaNetCoreEndpoint(owaEndpoint: string) {
    // this flight changes are temporary and will be removed after the server side change in progressed
    if (
        isBootFeatureEnabled('fwk-netcore-update-baseurl-for-consumer-account') &&
        owaEndpoint.includes('/owa/0/')
    ) {
        return owaEndpoint.replace('/owa/0/', '/owanetcore/');
    }
    return owaEndpoint.replace('/owa/', '/owanetcore/');
}
