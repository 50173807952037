import type { MailboxInfo } from 'owa-client-types';
import type { ApplicationSettingGroup, ApplicationSettings } from 'owa-application-settings';
import { getItem } from 'owa-local-storage';

export function addNetCoreAuthHeader(
    headers: Headers,
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo,
        dontErrorIfNotInitialized?: boolean
    ) => ApplicationSettings[TGroup],
    mailboxInfo?: MailboxInfo
) {
    try {
        const authToken =
            getApplicationSettingsCallback?.(
                'NetCore',
                mailboxInfo,
                true /* dontErrorIfNotInitialized */
            ).authToken ??
            JSON.parse(getItem(self, 'applicationSettingOverrides') ?? '')['NetCore.authToken'];
        if (authToken) {
            headers.set('Authorization', `Bearer ${authToken}`);
        }
    } catch {
        // ignore errors
    }
}
