import { LazyAction, LazyBootModule } from 'owa-bundling-light';

export const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "MsalAuth"*/ './lazyIndex'),
    { name: 'MsalAuth' }
);

export const lazyCreateMsalInstance = new LazyAction(lazyModule, m => m.createMsalInstance);

export const lazySignoutFromMsal = new LazyAction(lazyModule, m => m.signoutFromMsal);

export const lazyAcquireAccessTokenMsal = new LazyAction(lazyModule, m => m.acquireAccessTokenMsal);

export const lazyGetAuthTokenMsal = new LazyAction(lazyModule, m => m.getAuthTokenMsal);
export const lazyGetAnchorMailbox = new LazyAction(lazyModule, m => m.getAnchorMailbox);
export const lazyLoginUserMsal = new LazyAction(lazyModule, m => m.loginUserMsal);

export const lazyExecuteMsalNestedAppAuthRequest = new LazyAction(
    lazyModule,
    m => m.executeMsalNestedAppAuthRequest
);

export const lazyOnActivityTimeoutErrorForMSAL = new LazyAction(
    lazyModule,
    m => m.onActivityTimeoutErrorForMSAL
);

export const lazySetIsSigninRequired = new LazyAction(lazyModule, m => m.setIsSigninRequired);

export const lazyOnFederatedLogout = new LazyAction(lazyModule, m => m.onFederatedLogout);
