import type { MailboxInfo } from 'owa-client-types';
import type { ApplicationSettingGroup, ApplicationSettings } from 'owa-application-settings';
import { getItem } from 'owa-local-storage';

export function shouldNetCoreFallback(
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo,
        dontErrorIfNotInitialized?: boolean
    ) => ApplicationSettings[TGroup],
    mailboxInfo?: MailboxInfo
) {
    try {
        return (
            getApplicationSettingsCallback?.(
                'NetCore',
                mailboxInfo,
                true /* dontErrorIfNotInitialized */
            ).shouldFallback ??
            JSON.parse(getItem(self, 'applicationSettingOverrides') ?? '')[
                'NetCore.shouldFallback'
            ] ??
            true /* default to fallback true */
        );
    } catch {
        return true /* default to fallback true */;
    }
}
