import { logStartGreyError } from 'owa-analytics-start';
import { sanitize } from 'owa-data-worker-utils/lib/sanitize';
import { isOfflineBootEnabled } from 'owa-offline/lib/offlineBoot';
import { markFunction } from 'owa-performance';
import { getActionsQueuedHint } from 'owa-queued-actions/lib/queue/getActionsQueuedHint';
import { getConfig } from 'owa-service/lib/config';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { getWrappedWorker } from '../util/getWrappedWorker';

export const applyTombstonesToSessionData = markFunction(
    applyOfflineTombstonesToSessionData,
    'tomb'
);

async function applyOfflineTombstonesToSessionData(networkSD: SessionData) {
    // Avoid a data worker roundtrip if there aren't any tombstones to apply.
    if (!isOfflineBootEnabled() || !getActionsQueuedHint()) {
        return networkSD;
    }

    let sessionData = networkSD;

    // The account source list store is not initialized until after the session data has been retrieved
    const config = getConfig();
    const mailboxInfo = config.getGlobalSettingsAccountMailboxInfo?.();
    const databaseId = config.getGlobalSettingsAccountPersistenceId?.();

    try {
        const worker = getWrappedWorker();
        if (!worker?.client) {
            throw new Error('Worker client is not available');
        }

        if (!databaseId || !mailboxInfo) {
            throw new Error('Can not get a account to resolve database');
        }

        sessionData = await worker.client.applyTombstonesToSessionData(
            networkSD,
            databaseId,
            sanitize(mailboxInfo)
        );
    } catch (err) {
        logStartGreyError('ApplyTombstonesToSessionData', err);
    }

    return sessionData;
}
