import isValidAccountKey from './isValidAccountKey';
import type AccountKey from './AccountKey';

/**
 * This function will validate and compare two account keys
 * to let us know if they are of the same coprincipal account.
 *
 * A direct comparison of the user email addresses or userIdentity is no longer
 * sufficient as the same user can have multiple accounts.
 *
 * @param mailbox1AccountKey account key of the first mailbox.
 * @param mailbox2AccountKey account key of the second mailbox.
 * @returns true if the two account keys are valid and of the same coprincipal account.
 */
export default function isSameCoprincipalAccountByAccountKeys(
    mailbox1AccountKey: AccountKey | undefined | null,
    mailbox2AccountKey: AccountKey | undefined | null
): boolean {
    let isSame = false;

    if (isValidAccountKey(mailbox1AccountKey) && isValidAccountKey(mailbox2AccountKey)) {
        isSame = mailbox1AccountKey === mailbox2AccountKey;
    }

    return isSame;
}
