import type { MailboxInfo } from 'owa-client-types';
import type { ApplicationSettingGroup, ApplicationSettings } from 'owa-application-settings';
import { getItem } from 'owa-local-storage';

export function shouldCompareNetCoreResponse(
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo,
        dontErrorIfNotInitialized?: boolean
    ) => ApplicationSettings[TGroup],
    mailboxInfo?: MailboxInfo
) {
    try {
        return !!(
            getApplicationSettingsCallback?.(
                'NetCore',
                mailboxInfo,
                true /* dontErrorIfNotInitialized */
            )?.shouldCompareResponse ??
            JSON.parse(getItem(self, 'applicationSettingOverrides') ?? '')[
                'NetCore.shouldCompareResponse'
            ]
        );
    } catch {
        return false;
    }
}
