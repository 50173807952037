export default function (
    callback: (isVisible: boolean) => void
): ReturnType<typeof self.requestAnimationFrame> | undefined {
    // requestAnimationFrame won't get called if the page is not visible. So let's make sure the page is
    // currently visible. If not, then let's end the action here as there is no render time
    if (self.document && self.document.visibilityState == 'visible') {
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * This is intended as this is the wrapper everyone should be using */
        return self.requestAnimationFrame(function endActionWithTime() {
            callback(true);
        });
    } else {
        // If the page is not visible, we should call the callback within a new task
        setTimeout(() => {
            callback(false);
        }, 0);
        return undefined;
    }
}
