import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootNativePostBoot" */ './lazyFunctions'),
    { name: 'AppBootNativePostBoot' }
);

export const lazySetupNativeHostPostBoot = new LazyAction(
    lazyModule,
    m => m.lazySetupNativeHostPostBoot.importAndExecute
);
