import type { MailboxInfoSource } from 'owa-client-types';
import type { AccountSourceType } from './AccountSourceType';

/**
 * MailboxInfo's are identified as belonging to a removed account by the isRemoved flag is true
 */
export interface RemovedMailboxInfo extends MailboxInfoSource {
    isRemoved: true;
}

/**
 * Defines the infomration that is kept around for removed accounts
 */
export interface RemovedAccount {
    type: 'RemovedAccount';
    sourceType: AccountSourceType;
    sourceId: string;
    persistenceId: string;
    mailboxInfo: RemovedMailboxInfo;
}

/**
 * Type predicate to determine if the item is a RemovedAccount
 * @param value item to be checked to determine if it is a RemovedAccount
 * @returns True if it is a removed account
 */
/* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
 * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
 *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
export function isRemovedAccount(value?: any): value is RemovedAccount {
    return value?.type === 'RemovedAccount';
}
