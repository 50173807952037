import { isOneOf } from './isOneOf';
import type { TraceErrorObject } from 'owa-trace';

const NetworkErrors = [
    ': Syntax error',
    'SyntaxError:',
    'A network error occurred',
    'Failed to fetch',
    'NetworkError',
    'Network request failed',
    'Internet connection appears to be',
    'The network connection was lost.',
    'Could not connect to the server',
    'cancelled. URL:',
    'ErrorSessionTimeout',
    'Loading chunk ',
    'Unexpected server response (0)',
    'Unexpected end of JSON input',
    'Unexpected token',
    'Invalid character',
    'Unexpected end of input',
    'The operation was aborted',
    'Failed to load javascript',
    'disableAllRequests',
    'The service worker navigation preload request failed due to a network error',
    'Load failed',
    'Request failed to complete',
    'AbortError: Fetch is aborted',
    'Decoding failed',
    'Content-Length header of network response exceeds response Body',
    'unexpected end of data at line  column  of the JSON data',
    'unexpected character at line 1 column 1 of the JSON data',
    'The string did not match the expected pattern',
    'Service request blocked because disableAllRequests is enabled', // For testing purposes when we want to simulate a network error
    'NoNetwork',
];

export function isNetworkError(message: string | undefined, error?: TraceErrorObject) {
    return (
        isOneOf(NetworkErrors, message) ||
        error?.networkError ||
        // Errors thrown by mini-css-extract-plugin, despite *actually* being handled by LazyModule,
        // are still passed to the `unhandledrejection` window handler. Since it's still leaking through
        // the global handler, we need to handle it here.
        (error as any)?.code === 'CSS_CHUNK_LOAD_FAILED'
    );
}
