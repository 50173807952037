import { getConfig } from './config';
import { type HttpStatusCode, isAuthStatusCode } from 'owa-http-status-codes';
import { tryBackgroundAuth } from './tryBackgroundAuth';
import type { TraceErrorObject } from 'owa-trace';
import type { RequestOptions } from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import { isAuthNeeded } from './isAuthNeeded';
import { doAuthRedirect } from './doAuthRedirect';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * * Adding baseline for existing errors
 *	> 'getNativeHostVersion' import from 'owa-config' is restricted. For capabilities, create a new ApplicationSetting for the feature and scope Platform to OutlookNativeHost. */
import { getNativeHostVersion } from 'owa-config';
import { logStartCoreUsage, logStartGreyError } from 'owa-analytics-start';
import { AuthorizationHeaderName } from './createDefaultHeader';

const AUTH_NEEDED = 'AuthNeeded';

export default function fetchHandler<T>(
    actionName: string,
    response: ResponseWithExtraParams,
    requestOptions: RequestOptions | MailboxRequestOptions,
    callstackAtRequest?: string
): Promise<T | Response> {
    const errorMessage = getErrorMessage(response, requestOptions);
    const config = getConfig();

    if (errorMessage === AUTH_NEEDED) {
        response.source = AUTH_NEEDED;

        // We only need to redirect if we are not doing token based auth
        if (config.onAuthFailed) {
            config.onAuthFailed(response.headers, requestOptions.mailboxInfo);
        } else {
            // temporary logging to help diagnose unexpected auth redirects in native host
            if (getNativeHostVersion()) {
                logStartGreyError(
                    'UnexpectedAuthRedirectInNativeHost',
                    undefined /* error object */,
                    {
                        actionName,
                        status: response.status,
                        callstackAtRequest,
                        authHeaderPresent: requestOptions.headers?.has(AuthorizationHeaderName),
                        mailboxInfoPresent: requestOptions.mailboxInfo != undefined,
                    }
                );
            }
            doAuthRedirect(response.headers, requestOptions.mailboxInfo);
        }
    }

    // Check if client is getting OwaHipRequiredExcpetion then they should be redirected to jsMvvm hip control
    // After users successfuly resolve captcha, they will be redirected to /owa which will force opt in user to /mail
    if (response.status == 412) {
        logStartCoreUsage('HipPreconditionFailed', {
            configState: config.checkAndHandleHipException ? 'defined' : 'undefined',
        });
        config.checkAndHandleHipException?.(response.headers, requestOptions.mailboxInfo);
    }

    // Check if client failed to fetch auth token
    if (actionName == 'GetAccessTokenforResource') {
        config.checkAndHandleGATFRFailure?.(response.headers);
    }

    if (requestOptions.returnFullResponseOnSuccess) {
        response.callstackAtRequest = callstackAtRequest;
        response.responseErrorMessage = `RequestFailed:${actionName} with ${response.source}`;
        return errorMessage ? Promise.reject<Response>(response) : Promise.resolve(response);
    }

    if (errorMessage) {
        const responseError = createServerFailureError(
            actionName,
            errorMessage,
            response,
            callstackAtRequest
        );
        responseError.response = response;
        throw responseError;
    }

    if (response.headers?.get('X-OWA-STO') != null) {
        tryBackgroundAuth(requestOptions);
    }

    if (requestOptions.returnResponseHeaders) {
        return Promise.resolve(response);
    }

    return response.json().catch((e: Error) => {
        throw createServerFailureError(actionName, e?.message, response, callstackAtRequest);
    });
}

interface ResponseWithExtraParams extends Response {
    source?: string;
    callstackAtRequest?: string;
    responseErrorMessage?: string;
}

/**
 * Gets response error message. Returns null if no errors
 */
function getErrorMessage(
    response: ResponseWithExtraParams,
    requestOptions: RequestOptions | MailboxRequestOptions
): string | null {
    if (isAuthStatusCode(response.status) && isAuthNeeded(requestOptions)) {
        return AUTH_NEEDED;
    }

    if (!response.ok) {
        return (
            (response.headers && response.headers.get('x-owa-error')) ||
            response.statusText ||
            (response.status === 449 ? 'RetryWith' : '')
        );
    }

    return null;
}

function createServerFailureError(
    actionName: string,
    message: string,
    response: Response,
    callstackAtRequest: string | undefined
) {
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    const responseError: TraceErrorObject = new Error(`${actionName} failed: ${message}`);
    if (callstackAtRequest) {
        responseError.additionalInfo = { callstackAtRequest };
    }
    responseError.fetchErrorType = message == AUTH_NEEDED ? AUTH_NEEDED : 'ServerFailure';
    responseError.httpStatus = response.status;
    responseError.xowaerror = response.headers.get('x-owa-error') || undefined;
    responseError.xinnerexception = response.headers.get('x-innerexception') || undefined;
    return responseError;
}
