import {
    type FirstPartyMailboxProvider,
    type MailboxProvider,
    FirstPartyMailboxProvidersSet,
    MailboxProvidersSet,
    type ThirdPartyMailboxProvider,
    ThirdPartyMailboxProvidersSet,
} from './MailboxProvider';

/**
 * Checks if the provider is a first party mailbox provider
 * @param provider Specifies a provider string to be checked
 * @returns True if it is a FirstPartyMailboxProvider false otherwise
 */
export function isFirstPartyMailboxProvider(
    provider?: string | null
): provider is FirstPartyMailboxProvider {
    return typeof provider === 'string' && FirstPartyMailboxProvidersSet.has(provider);
}

/**
 * Checks if the provider is a third party mailbox provider
 * @param provider Specifies a provider string to be checked
 * @returns True if it is a ThirdPartyMailboxProvider false otherwise
 */
export function isThirdPartyMailboxProvider(
    provider?: string | null
): provider is ThirdPartyMailboxProvider {
    return typeof provider === 'string' && ThirdPartyMailboxProvidersSet.has(provider);
}

/**
 * Checks if the provider is a mailbox provider
 * @param provider Specifies a provider string to be checked
 * @returns True if it is a MailboxProvider false otherwise
 */
export default function isMailboxProvider(provider?: string | null): provider is MailboxProvider {
    return typeof provider === 'string' && MailboxProvidersSet.has(provider);
}
