import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';
import { logStartUsage } from 'owa-analytics-start';

/**
 * Update the accountDescription of the specified Coprincipal account source in the store
 */
export default mutatorAction(
    'UPDATE_COPRINCIPAL_ACCOUNT_DESCRIPTION',
    function (sourceId: string, accountDescription: string): void {
        if (!getAccountBySourceId(sourceId)) {
            logStartUsage('accountDescription_UpdateCoprincipalSourceIdNotFound');
            return;
        }
        const store = getAccountSourceListStore();
        for (const source of store.sources) {
            if (source.sourceId === sourceId) {
                source.accountDescription = accountDescription;
            }
        }
    }
);
