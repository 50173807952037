import { mutatorAction } from 'satcheljs';

import type { RemovedAccount } from 'owa-account-source-list-types';
import getAccountSourceListStore from '../store/accountSourceListStore';
import {
    removeResourcesFromSourcesBySourceIdMap,
    removeSourceFromSourcesBySourceIdMap,
} from '../utils/sourcesBySourceIdMapUtils';

export default mutatorAction(
    'REMOVE_COPRINCIPAL_ACCOUNT_BY_ID',
    function (accountSourceId: string, removedAccount?: RemovedAccount): void {
        const store = getAccountSourceListStore();
        store.sources = store.sources.filter(
            accountSource => accountSource.sourceId !== accountSourceId
        );

        if (accountSourceId === store.globalSettingsSourceId) {
            // The current priority is providing the API for usage, so currently we make sure there
            // is an account for the API to use. When the me control is integrated it will be responsible
            // managing the  global setting account and this code will be updated. This is tracked by
            // Work Item 131822: Integrate the me control and account source list
            store.globalSettingsSourceId =
                store.sources.length > 0
                    ? store.sources[0].sourceId
                    : getAccountSourceListStore().globalSettingsSourceId;
        }

        removeSourceFromSourcesBySourceIdMap(accountSourceId);
        removeResourcesFromSourcesBySourceIdMap(accountSourceId);

        if (removedAccount !== undefined) {
            store.removedAccounts.push(removedAccount);
        }
    }
);
