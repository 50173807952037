import type { ModulesEnabledForAppBootstrap } from 'owa-workloads-module';
import { Module } from 'owa-workloads-module';
import { hasQueryStringParameter, getQueryStringParameter } from 'owa-querystring';

export function determineModule(): ModulesEnabledForAppBootstrap {
    const pathname = getLoadModule() || window?.location?.pathname;

    if (pathname) {
        const pathnameParts = pathname.split('/');
        if (checkDeepLink('mail', pathnameParts)) {
            return Module.MailDeepLink;
        } else if (checkDeepLink('calendar', pathnameParts)) {
            return Module.CalendarDeepLink;
        } else if (
            pathname.indexOf('/calendar') == 0 ||
            pathname.indexOf('/hosted/calendar') == 0
        ) {
            return Module.Calendar;
        } else if (pathname.indexOf('/people') == 0 || pathname.indexOf('/hosted/people') == 0) {
            return Module.People;
        } else if (pathname.indexOf('/files') == 0) {
            return Module.FilesHub;
        } else if (pathname.indexOf('/host/') == 0) {
            return Module.AppHost;
        } else if (pathname.indexOf('/groups') == 0 || pathname.indexOf('/hosted/groups') == 0) {
            return Module.Groups;
        } else if (
            pathname.indexOf('/eventify') == 0 ||
            pathname.indexOf('/hosted/eventify') == 0
        ) {
            return Module.Eventify;
        } else if (pathname.indexOf('/places') == 0 || pathname.indexOf('/hosted/places') == 0) {
            return Module.MicrosoftPlaces;
        }
    }
    return Module.Mail;
}

function checkDeepLink(vdir: string, pathnameParts: string[]): boolean {
    return (
        (pathnameParts[1] == vdir &&
            (pathnameParts[2]?.indexOf('deeplink') == 0 ||
                pathnameParts[3]?.indexOf('deeplink') == 0)) ||
        (pathnameParts[1] == 'hosted' &&
            pathnameParts[2] == vdir &&
            (pathnameParts[3]?.indexOf('deeplink') == 0 ||
                pathnameParts[4]?.indexOf('deeplink') == 0))
    );
}

function getLoadModule(): string | null {
    const pathname = window?.location?.pathname;
    if (pathname && pathname.includes('oobe')) {
        if (hasQueryStringParameter('loadModule')) {
            return getQueryStringParameter('loadModule');
        } else {
            return null;
        }
    }

    return null;
}
