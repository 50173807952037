import { createDefaultHeaders } from './createDefaultHeader';
import type {
    RequestOptions,
    InternalRequestOptions,
    HeadersWithoutIterator,
} from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';

export default function createFetchOptions(
    options?: RequestOptions | MailboxRequestOptions
): Promise<InternalRequestOptions> {
    options = options || {};

    const headers: HeadersWithoutIterator = options.headers
        ? new Headers(<Headers>options.headers)
        : new Headers();

    const returnObj: InternalRequestOptions = {
        ...options,
        method: options.method || 'POST',
        credentials: 'include',
        headers,
    };

    const hasAuthHeader = headers?.has('Authorization');
    const hasAnchorMailbox = headers?.has('x-anchormailbox');

    // Update the canary headers
    return createDefaultHeaders(
        hasAuthHeader,
        options.mailboxInfo,
        hasAnchorMailbox,
        options.customIdType,
        options.isAnonymousAllowed
    ).then(defaultHeaders => {
        Object.keys(defaultHeaders).forEach(key => {
            // Only set the header if it is not already set
            if (!headers.has(key) || !headers.get(key)) {
                headers.set(key, defaultHeaders[key]);
            }
        });

        return returnObj;
    });
}
