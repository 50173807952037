export type LocStringsHandler = {
    registerHandler(
        locale: string,
        handler: (locStringUrl: string) => Promise<void>
    ): Promise<void>;
};
export interface LocWindow {
    _locStrings?: LocStringsHandler;
}
export interface LocationData {
    search: string;
    readonly host: string;
    readonly protocol: string;
    readonly hostname: string;
    href: string;
    readonly pathname: string;
    readonly hash: string;
    readonly origin: string;
    readonly port: string;
}
export interface LocalStorageData {
    getItem: (key: string) => string | null;
    setItem: (key: string, value: string) => void;
    removeItem: (key: string) => void;
}
export interface WindowData extends LocWindow {
    readonly location: LocationData;
    readonly innerHeight: number;
    readonly innerWidth: number;
    readonly document: Document | null;
    readonly isPwa: boolean;
    readonly cookie: string;
    readonly localStorage: LocalStorageData | undefined;
    readonly origin: string;
}
/* eslint-disable no-restricted-globals -- Since this is mirroring window data, we need to use 'window' */
class NativeWindowData implements WindowData {
    get location() {
        return window.location;
    }
    get innerHeight() {
        /* eslint-disable-next-line no-restricted-properties, owa-custom-rules/forbid-properties-access-outside-specific-function -- (https://aka.ms/OWALintWiki)
         *	> 'innerHeight' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. (https://aka.ms/OWALintWiki)
         * This is baseline exception, if you edit this file you need to fix this exception.
         *	> Property 'innerHeight' must be accessed within 'wrapForcedLayout' imported from 'owa-performance'. */
        return window.innerHeight;
    }
    get innerWidth() {
        /* eslint-disable-next-line no-restricted-properties, owa-custom-rules/forbid-properties-access-outside-specific-function -- (https://aka.ms/OWALintWiki)
         *	> 'innerWidth' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. (https://aka.ms/OWALintWiki)
         * This is baseline exception, if you edit this file you need to fix this exception.
         *	> Property 'innerWidth' must be accessed within 'wrapForcedLayout' imported from 'owa-performance'. */
        return window.innerWidth;
    }
    get document() {
        return window.document;
    }
    get isPwa() {
        return window.matchMedia('(display-mode: standalone)').matches;
    }
    get cookie() {
        try {
            // eslint-disable-next-line @microsoft/sdl/no-cookies  -- (https://aka.ms/OWALintWiki) Standard way to read the cookie that is safe across workers
            return window.document.cookie;
        } catch {
            // Some browsers (e.g. Firefox) throw an exception when accessing document.cookie
            return '';
        }
    }
    get localStorage() {
        try {
            /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
             * BASELINE. Do not copy and paste!
             *	> 'localStorage' is restricted from being used. Use owa-local-storage instead. */
            return window.localStorage;
        } catch (e) {
            /* In some situations e.g. when running in an iframe, accessing localStorage will give
            access denied error if access to third party cookies and site data are disabled in the browser.*/
            return undefined;
        }
    }
    get origin() {
        return window.origin;
    }
    get _locStrings() {
        return (<LocWindow>(<any>window))._locStrings;
    }
}
let windowData: WindowData;
export function getWindowData(): WindowData {
    if (!windowData && typeof window !== 'undefined') {
        windowData = new NativeWindowData();
    }
    return windowData;
}
/* eslint-enable no-restricted-globals */
export function setWindowData(w: WindowData) {
    windowData = w;
}
