// The modules supported by module switcher.
export enum Module {
    Mail = 'Mail',
    Calendar = 'Calendar',
    People = 'People',
    PeopleNew = 'PeopleNew',
    FilesHub = 'FilesHub',
    Tasks = 'Tasks',
    Eventify = 'Eventify',
    PublishedCalendar = 'PublishedCalendar',
    MailDeepLink = 'MailDeepLink',
    AppHost = 'AppHost',
    CalendarDeepLink = 'CalendarDeepLink',
    Groups = 'Groups',
    MicrosoftPlaces = 'MicrosoftPlaces',
    Addison = 'Addison',
}

// This is all the modules that are enabled to use the shared bootstrap code of
// appBootstrap
export type ModulesEnabledForAppBootstrap = Extract<
    Module,
    | Module.Mail
    | Module.Calendar
    | Module.People
    | Module.FilesHub
    | Module.MailDeepLink
    | Module.AppHost
    | Module.CalendarDeepLink
    | Module.Groups
    | Module.Eventify
    | Module.MicrosoftPlaces
>;

// This list all the modules that are enabled for switching within the same load of the page
// without a url redirect
export type ModulesEnabledForSwitch = Extract<
    Module,
    Module.Mail | Module.Calendar | Module.People | Module.FilesHub | Module.AppHost | Module.Groups
>;
