import { logGreyErrorFromAccounts } from 'owa-account-analytics';
import setCoprincipalAccountPolicyStateMutator from '../actions/setCoprincipalAccountPolicyStateMutator';
import { default as getAccountBySourceId } from '../selectors/getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import { PolicyStateTypeEnum } from 'owa-account-source-list-types';
import onAccountBadState from '../actions/onAccountBadState';

/* Validates that the sourceId is valid and then sets the account's policy state
 * @param sourceId id of the coprincipal account
 * @param state state to which the account is to be set
 */
export default function setCoprincipalAccountPolicyState(
    sourceId: string,
    state: PolicyStateTypeEnum | undefined
): void {
    const account = getAccountBySourceId(sourceId);
    if (!accountRankTypeChecker.isCoprincipal(account)) {
        const errorMessage = 'AcctInit-Cannot set PolicyState, coprincipal account is not in store';
        const e = new Error(errorMessage);

        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logGreyErrorFromAccounts(errorMessage, e, {
            cnt: getAccountSourceListStore().sources.length,
            sid: typeof sourceId,
            sidl: sourceId.length,
        });

        return;
    }

    if (account.policyState === state) {
        // The policy state has not changed
        return;
    }
    if (account.policyState === PolicyStateTypeEnum.PolicyError) {
        onAccountBadState(account.mailboxInfo);
    }

    setCoprincipalAccountPolicyStateMutator(sourceId, state);
}
