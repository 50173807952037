import assert from 'owa-assert/lib/assert';
import getAccountSourceListStore from '../store/accountSourceListStore';
import type { AccountSource, CoprincipalAccountSource } from '../store/schema/AccountSourceList';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import getAccountBySourceId from './getAccountBySourceId';

/**
 * Used to obtain the AccountSource of the account where global settings are stored
 * @returns AccountSource to be used for getting the global settings
 */
export default function getGlobalSettingsAccount(): AccountSource {
    const source = getAccountBySourceId(getAccountSourceListStore().globalSettingsSourceId);

    assert(
        source,
        'AccountSourceListStore is not initialized',
        () =>
            `cnt=${
                getAccountSourceListStore().sources.length
            } gs=${typeof getAccountSourceListStore().globalSettingsSourceId} s=${typeof source}`
    );

    return source;
}

export function getGlobalSettingsCoprincipalAccount(): CoprincipalAccountSource {
    const account = getGlobalSettingsAccount();
    if (accountRankTypeChecker.isCoprincipal(account)) {
        return account;
    }

    // This is unexpected, the global settings account must always be a coprincipal account
    throw new Error(
        'AccountSourceListStore is not initialized, global settings account is not a coprincipal account'
    );
}
