const allow_exceptions = ['got invalid value null', 'got invalid value undefined'];
const got_invalid_value = 'got invalid value';
const cannot_represent = 'cannot represent';

/**
 * Graphql sometimes serializes user data into error messages, so we try to sanitize the known cases.
 * https://github.com/graphql/graphql-js/issues/2629
 */
export function sanitizeGraphqlErrorMessage(message?: string | null): string | undefined | null {
    if (!message) {
        return message;
    }

    // There are a few special cases that we know are safe to allow through
    for (const exception of allow_exceptions) {
        if (message.includes(exception)) {
            return message;
        }
    }

    // The general form of the dangerous error is 'Variable <name> got invalid value <value from args> at "<error path>"; <error details>'
    const index = message.indexOf(got_invalid_value);
    if (index !== -1) {
        const errorPrefix = message.substring(0, index + got_invalid_value.length);

        // Try to preserve the trailing detailed info (appended with a semicolon) that can be critical to understand the error.
        let path = '';
        let trailingError = '';
        const pieces = message.split('; ');
        if (pieces.length == 2) {
            // Try to preserve the error path if provided
            const pathRegex = new RegExp(/ at "[\w.\[\]]+"$/);
            path = pathRegex.exec(pieces[0])?.[0] || '';

            trailingError = '; ' + sanitizeTrailingError(pieces[1]);
        }
        message = `${errorPrefix}...${path}${trailingError}`;
    }

    return message;
}

// The trailing error details may also contain user data
function sanitizeTrailingError(trailingError: string): string {
    const index = trailingError.indexOf(cannot_represent);
    if (index !== -1) {
        trailingError = `${trailingError.substring(0, index + cannot_represent.length)}...`;
    }

    return trailingError;
}
