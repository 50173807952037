import { createStore } from 'owa-satcheljs';
import type { AccountAndCoprincipalSourceId, AccountSourceList } from './schema/AccountSourceList';

const defaultStore: AccountSourceList = {
    globalSettingsSourceId: '',
    sourcesBySourceId: new Map<string, AccountAndCoprincipalSourceId>(),
    sources: [],
    removedAccounts: [],
    licensingIdentities: [],
};

export default createStore<AccountSourceList>('AccountSourceList', defaultStore);
