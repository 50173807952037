/* eslint-disable-next-line @typescript-eslint/no-restricted-imports --
 * This file is a wrapper around satcheljs's createStore. All other usage should
 * go through here. */
import { createStore as createSatchelStore } from 'satcheljs';
import { once } from 'owa-lodash';
import { isBootFeatureEnabled } from 'owa-metatags';

// // Copied from owa-config rather than import to avoid a circular dependency between this package,
// // owa-config, and owa-workloads
function isRunningOnWorker() {
    return typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;
}

const storeMap: Set<string> = new Set();

/**
 * Facade wrapper around createStore from satcheljs. We want to make sure we don't bother
 * creating observables in worker threads.
 */
export function createStore<T>(key: string, initalState: T): () => T {
    // Currently just directly invokes `createStore()` from satchel,
    // due to a break introduced by checking boot flights in the module scope of the worker.
    // The entire original PR was not reverted so that this work could be maintained and fixed without
    // re-doing all of the work to funnel all calls through here.

    if (!isRunningOnWorker() && isBootFeatureEnabled('fwk-createstore-deffered')) {
        // the createStore from satchel is intended to be used as a side effect
        // however it sets the initial state in the global store
        // which makes it observable and can be costly especially if the inital store is large
        // to avoid this cost as a side effect, wrapping this logic in a once so the expensive part
        // happens when the scenario runs for the first time and not when it is evaluated
        // because this is happening, we need to redo the logic to ensure that there are not multiple
        // stores created with the same key
        if (storeMap.has(key)) {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * It is intended that we break up these error messages */
            throw new Error(`Store with key ${key} already exists`);
        } else {
            storeMap.add(key);
            return once(() => createSatchelStore(key, initalState)());
        }
    } else {
        return createSatchelStore(key, initalState);
    }
}
