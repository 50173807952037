import findMetatag from './findMetatag';

function tryAddSuffix(url: string = '', suffix: string = ''): string | undefined {
    if (url && url.indexOf('/') > -1 && suffix) {
        return url + suffix;
    }

    return undefined;
}

export const AriaUrlSuffix = 'Collector/3.0/';
export const OneDsCollectorUrlSuffix = 'OneCollector/1.0/';

export function getAriaUrl(): string | undefined {
    const ariaUrl = findMetatag('ariaUrl');
    return tryAddSuffix(ariaUrl, AriaUrlSuffix);
}

export function getOneDsUrl(): string | undefined {
    const ariaUrl = findMetatag('ariaUrl');
    return tryAddSuffix(ariaUrl, OneDsCollectorUrlSuffix);
}
