import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootAccountSourceList" */ './lazyFunctions'),
    { name: 'AppBootAccountSourceList' }
);

export const lazyUpdateAccountStateBasedOnPolicyProxy = new LazyAction(
    lazyModule,
    m => m.lazyUpdateAccountStateBasedOnPolicy.importAndExecute
);

export const lazyUpdateLicensingIdentitiesStateBasedOnAccountPolicyProxy = new LazyAction(
    lazyModule,
    m => m.lazyUpdateLicensingIdentitiesStateBasedOnAccountPolicy.importAndExecute
);

export const lazyEnsurePreRenderForAccountProxy = new LazyAction(
    lazyModule,
    m => m.lazyEnsurePreRenderForAccount.importAndExecute
);
export const lazyAreNewAccountAndExistingAccountsMutuallyCompliantProxy = new LazyAction(
    lazyModule,
    m => m.lazyAreNewAccountAndExistingAccountsMutuallyCompliant.importAndExecute
);
export const lazyOobeInitForNewlyAddedGlobalSettingsAccountProxy = new LazyAction(
    lazyModule,
    m => m.lazyOobeInitForNewlyAddedGlobalSettingsAccount.importAndExecute
);
export const lazyIsLicenseRequirementsCompliantProxy = new LazyAction(
    lazyModule,
    m => m.lazyIsLicenseRequirementsCompliant.importAndExecute
);
export const lazyAccountSourceListPostRenderProxy = new LazyAction(
    lazyModule,
    m => m.lazyAccountSourceListPostRender.importAndExecute
);

export const lazyAreAllAccountsPolicyAllowingConsumerAccountsProxy = new LazyAction(
    lazyModule,
    m => m.lazyAreAllAccountsPolicyAllowingConsumerAccounts.importAndExecute
);
