const FirstPartyMailboxProviders = [
    // This mailbox is provided by an AAD account
    'Office365',

    // This mailbox is an MSA account
    'Outlook',
] as const;

export const FirstPartyMailboxProvidersSet: Set<string> = new Set([...FirstPartyMailboxProviders]);

// Specifies the first party provider of the mailbox
export type FirstPartyMailboxProvider = typeof FirstPartyMailboxProviders[number];

const OtherMailboxProviders = [
    // This mailbox is provided by cloud cache for a Google account
    'Google',

    // This mailbox is provided by cloud cache for an ICloud account
    'ICloud',

    // This mailbox is provided by cloud cache for a Yahoo account
    'Yahoo',

    // This mailbox is provided by cloud cache using the IMAP protocol
    'IMAP',

    // This mailbox is provided by cloud cache using the POP3 protocol
    'POP3',

    // This mailbox is provided by a local Outlook data file
    'PstFile',

    // The provider of this mailbox is unknown
    'Unknown',
] as const;

export const ThirdPartyMailboxProvidersSet: Set<string> = new Set([...OtherMailboxProviders]);

// Specifies the third party provider of the mailbox
export type ThirdPartyMailboxProvider = typeof OtherMailboxProviders[number];

export const MailboxProvidersSet: Set<string> = new Set([
    ...FirstPartyMailboxProviders,
    ...OtherMailboxProviders,
]);

// Specifies the provider of the mailbox
export type MailboxProvider = FirstPartyMailboxProvider | ThirdPartyMailboxProvider;
