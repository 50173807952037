import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootFreezeDry" */ './lazyFunctions'),
    { name: 'AppBootFreezeDry' }
);

export const lazyKickOffFreezeDryProxy = new LazyAction(
    lazyModule,
    m => m.lazyKickOffFreezeDry.importAndExecute
);

export const lazySetShouldInitializeFreezeDryProxy = new LazyAction(
    lazyModule,
    m => m.lazySetShouldInitializeFreezeDry.importAndExecute
);
