import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootLocalDataAccountSupport" */ './index'),
    { name: 'AppBootLocalDataAccountSupport' }
);

export const lazyAddLocalDataAdditionalAccountProxy = new LazyAction(
    lazyModule,
    m => m.lazyAddLocalDataAdditionalAccount.importAndExecute
);

export const lazyDoCleanupForRemovedLocalDataAccountProxy = new LazyAction(
    lazyModule,
    m => m.lazyDoCleanupForRemovedLocalDataAccount.importAndExecute
);

export const lazyCheckM365InstallationProxy = new LazyAction(
    lazyModule,
    m => m.lazyCheckM365Installation.importAndExecute
);

export const lazyCheckPstPrerequisitesProxy = new LazyAction(
    lazyModule,
    m => m.lazyCheckPstPrerequisites.importAndExecute
);
