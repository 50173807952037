import type { AuthError } from '@azure/msal-browser-1p';
export type AuthStatus = 'None' | 'MsalAuthSuccess' | 'MsalAuthError';

let authStatus: AuthStatus = 'None';
let authError: string = '';
let fromCache: boolean | undefined;
let authErrorDetails:
    | {
          [key: string]: any;
      }
    | undefined;

export function setAuthStatusInfo(msalAuthStatus: AuthStatus, error?: AuthError, cache?: boolean) {
    authStatus = msalAuthStatus;
    fromCache = cache;
    if (error) {
        authError = error.errorCode;
        authErrorDetails = { errorMessage: error.message, subError: error.subError };
    }
}

export function getAuthStatusInfo() {
    return { authStatus, authError, fromCache, authErrorDetails };
}
