import type { RequestOptions } from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import isExplicitLogonRequest from './isExplicitLogonRequest';
import { isBootFeatureEnabled } from 'owa-metatags';

/**
 * Returns true if the request is explicit logon request, false otherwise.
 */
export default function isAuthRetriableForRequest(
    requestOptions: RequestOptions | MailboxRequestOptions | undefined
) {
    if (
        requestOptions?.mailboxInfo?.isAnonymous &&
        isBootFeatureEnabled('auth-disableAnonymousTokenForHeader')
    ) {
        // don't retry auth for anonymous requests
        return false;
    }

    if (isExplicitLogonRequest(requestOptions)) {
        // only retry auth for explicit logon if the option is set in the request options
        return !!requestOptions?.retryAuthForExplicitLogon;
    }

    return true;
}
