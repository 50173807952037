import type { OpxSessionInfo } from 'owa-config-types';
import { overrideSessionId } from './getSessionId';

let promise: Promise<OpxSessionInfo> | undefined;
let hostApp: string = '(none)';

export function getOpxHostData() {
    return promise;
}

export function getOpxHostApp(): string {
    return hostApp;
}

export function setOpxHostApp(app: string) {
    hostApp = app;
}

export function setOpxHostData(value: Promise<OpxSessionInfo>) {
    promise = value.then(opxHostData => {
        const sessionId = opxHostData.sessionId;
        if (sessionId) {
            overrideSessionId(sessionId);
        }
        return opxHostData;
    });
}
