import {
    OUTLOOK_DESKTOP,
    MAC_OUTLOOK,
    IOS_OUTLOOK,
    ANDROID_OUTLOOK,
    NATIVE,
    TEAMS,
    O365_SHELL,
    OFFICE_WEB,
    OFFICE_DESKTOP,
    WIN32_OUTLOOK_HUB,
    TEAMS_HUB,
    HUB,
    ANDROID_TEAMS,
    IOS_TEAMS,
    ANDROID_OUTLOOK_LITE,
    OUTLOOK_EXTENSION,
    SHAREPOINT,
    LOOP_APP,
    OWA_HUB,
    MONARCH_HUB,
    ANDORID_OUTLOOK_HUB,
    IOS_OUTLOOK_HUB,
    MAC_OUTLOOK_HUB,
} from 'owa-config/lib/types/HostApp';
import { getOpxHostApp } from 'owa-config/lib/getOpxHostData';
import { getHostHub } from 'owa-config/lib/getHostHub';
import { getNativeHostVersion } from 'owa-config/lib/enhancedHost';
import { isHostedInTeams } from 'owa-opx';
import type { HOST_APP_TYPES, HUB_TYPES } from './flagsDefaultsDeprecated';
import { getFlagDefaults } from './flagsDefaultsDeprecated';

export const isHostAppFeatureEnabled = (flagName: string): boolean => {
    const featureFlag = getFlagDefaults(flagName);

    if (featureFlag) {
        // getNativeHostVersion will be non-null during bootstrap in native, with a fallback to query string param
        if (getNativeHostVersion() && NATIVE in featureFlag) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            return featureFlag[NATIVE]!;
        }
        if (isHostedInTeams() && TEAMS in featureFlag) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            return featureFlag[TEAMS]!;
        }

        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        const hostHub: string = getHostHub()!;
        if (hostHub && hostHub in featureFlag) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            return featureFlag[hostHub as HUB_TYPES]!;
        }
        if (
            hostHub &&
            (hostHub === TEAMS_HUB ||
                hostHub === WIN32_OUTLOOK_HUB ||
                hostHub === OWA_HUB ||
                hostHub === MONARCH_HUB ||
                hostHub === OFFICE_WEB ||
                hostHub === OFFICE_DESKTOP ||
                hostHub === ANDORID_OUTLOOK_HUB ||
                hostHub === IOS_OUTLOOK_HUB ||
                hostHub === MAC_OUTLOOK_HUB) &&
            HUB in featureFlag
        ) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            return featureFlag[HUB]!;
        }

        const hostApp = getOpxHostApp();
        if (hostApp && hostApp in featureFlag) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            return featureFlag[hostApp as HOST_APP_TYPES]!;
        }
        if (
            hostApp &&
            (hostApp === OUTLOOK_DESKTOP ||
                hostApp === MAC_OUTLOOK ||
                hostApp === IOS_OUTLOOK ||
                hostApp === ANDROID_OUTLOOK ||
                hostApp === TEAMS ||
                hostApp === ANDROID_TEAMS ||
                hostApp === IOS_TEAMS ||
                hostApp === ANDROID_OUTLOOK_LITE ||
                hostApp === OUTLOOK_EXTENSION ||
                hostApp === O365_SHELL ||
                hostApp === SHAREPOINT ||
                hostApp === LOOP_APP) &&
            'opx' in featureFlag
        ) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            return featureFlag['opx']!;
        }

        return featureFlag['default'];
    }

    return false;
};
