import { getConfig } from './config';
import { addIdTypePreference } from './addIdTypePreference';
import checkAndLogMailboxInfo from './checkAndLogMailboxInfo';
import { getCanaryHeaders } from './setCanaryHeader';
import { getApp, getHostAppEcsFilter, getHostedUxEcsFilter, getHostedScenario } from 'owa-config';
import type { MailboxInfo } from 'owa-client-types';
import { addIncludeThirdPartyOnlineMeetingProvidersHeader } from './addIncludeThirdPartyOnlineMeetingProvidersHeader';
import type { IdType } from './RequestOptions';
import { isBootFeatureEnabled } from 'owa-metatags';

export const AuthorizationHeaderName = 'Authorization';
export const WebSessionTypeHeaderName = 'X-OwaWebSessionType';
const AnchorMailboxHeaderName = 'x-anchormailbox';

export async function createDefaultHeaders(
    hasAuthHeader?: boolean,
    mailboxInfo?: MailboxInfo,
    hasAnchorMailbox?: boolean,
    customIdType?: IdType,
    isAnonymousAllowed?: boolean
): Promise<{
    [key: string]: string;
}> {
    const defaultHeaders = createDefaultHeadersSync(mailboxInfo, customIdType);
    const config = getConfig();
    const anonymousAuthTokenBlockedFromHeader =
        mailboxInfo?.isAnonymous && isBootFeatureEnabled('auth-disableAnonymousTokenForHeader');

    if (
        !hasAuthHeader &&
        config.getAuthToken &&
        !anonymousAuthTokenBlockedFromHeader &&
        !isAnonymousAllowed
    ) {
        const token = await config.getAuthToken(undefined /* response headers */, mailboxInfo);
        if (token) {
            defaultHeaders[AuthorizationHeaderName] = token;

            // When we get the token we also want to get the web sesion type
            if (config.getWebSessionType) {
                const webSessionType = await config.getWebSessionType(mailboxInfo);
                if (webSessionType) {
                    defaultHeaders[WebSessionTypeHeaderName] = webSessionType;
                }
            }
        }
    }

    if (!hasAnchorMailbox && config.getAnchorMailbox) {
        const anchorMailbox = await config.getAnchorMailbox(mailboxInfo);
        if (anchorMailbox) {
            defaultHeaders[AnchorMailboxHeaderName] = anchorMailbox;
        }
    }

    // if we don't have an auth header, we need to add the canary headers
    if (!hasAuthHeader && !defaultHeaders[AuthorizationHeaderName]) {
        Object.assign(defaultHeaders, getCanaryHeaders());
    }

    return defaultHeaders;
}

export function createDefaultHeadersSync(
    mailboxInfo?: MailboxInfo,
    customIdType?: IdType
): {
    [key: string]: string;
} {
    var hostedUx = getHostedUxEcsFilter();
    const defaultHeaders: Record<string, string> = {
        'X-Req-Source': getApp(),
        'x-owa-hosted-ux': hostedUx,
    };

    // we set the x-owa-host-app header only if the hostedUx is true
    if (hostedUx == 'true') {
        // Undefined is the default value
        defaultHeaders['x-owa-host-app'] = getHostAppEcsFilter() || 'undefined';

        const hostedScenario = getHostedScenario();
        if (hostedScenario) {
            defaultHeaders['x-owa-hosted-scenario'] = hostedScenario;
        }
    }

    checkAndLogMailboxInfo(getConfig(), 'Acct-DefaultHeadersMailbox', mailboxInfo);

    addIdTypePreference(defaultHeaders, customIdType ?? 'ImmutableId', mailboxInfo);
    addIncludeThirdPartyOnlineMeetingProvidersHeader(defaultHeaders);

    // Add tenant ID header using account-specific configuration if mailboxInfo is present.
    const config = getConfig();
    const tenantId = config.getTenantId ? config.getTenantId(mailboxInfo) : undefined;
    if (tenantId) {
        defaultHeaders['X-TenantId'] = tenantId;
    }

    return defaultHeaders;
}
